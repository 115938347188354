import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../components/Header";
import FooterComponent from "../Footer";
import { useMediaQuery } from "@mui/material";

// import { useSelector } from "react-redux";
function Loading() {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box>
      {/* <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div >
        <img src={"../../../assets/images/loading.gif"} />
      </div>
    </div> */}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
        position="fixed"
      >
        {/* <CircularProgress /> */}
        {isMobile ? (
          <img src={"../../../assets/images/loading-mobile.gif"} alt="loading..." />
        ) : (
          <img src={"../../../assets/images/loader3.gif"} alt="loading..." />
        )}
      </Box>
      <Box position="fixed" left="0" top="0" right="0">
        <Header />
      </Box>
      <Box position="fixed" left="0" bottom="0" right="0">
        <FooterComponent />
      </Box>
    </Box>
  );
}

export default Loading;
