import "../../../styles.css";
import React from "react";
import TextQuestionComponent from "./TextQuestionComponent";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import MultiSelectQuestionComponent from "./MultiSelectQuestionComponent";
import ConditonalRadioQuestionComponent from "./ConditonalRadioQuestionComponent";
import SelectQuestionComponent from "./SelectQuestionComponent";
import NumberQuestionComponent from "./NumberQuestionComponent";
import DynamiCertificateQuestion from "./DynamiCertificateQuestion";
import DynamicMultiSelectQuestionComponent from "./DynamicMultiSelectQuestionComponent";

function ConditionalQuestions({ question, questionsObject }) {
  return (
    <Grid item xs={12} my={1}>
      <Box flex={1} overflow="auto">
        {question?.conditional?.map((conditionalField, i) => {
          return questionsObject?.map((conditionalQuestion, i) => {
            if (
              conditionalQuestion.questionId === conditionalField.questionId
            ) {
              switch (conditionalQuestion.type) {
                case "text":
                  return (
                    <TextQuestionComponent question={conditionalQuestion} />
                  );
                case "number":
                  return (
                    <NumberQuestionComponent question={conditionalQuestion} />
                  );
                case "select":
                  return (
                    <SelectQuestionComponent
                      question={conditionalQuestion}
                      questionsObject={questionsObject}
                    />
                  );
                case "multiSelect":
                  return (
                    <>
                      {conditionalQuestion?.certificateMultiselect ? (
                        <DynamiCertificateQuestion
                          question={conditionalQuestion}
                        />
                      ) : conditionalQuestion?.dynamicMultiSelect ? (
                        <DynamicMultiSelectQuestionComponent
                          question={conditionalQuestion}
                        />
                      ) : (
                        <MultiSelectQuestionComponent
                          question={conditionalQuestion}
                        />
                      )}
                    </>
                  );
                case "radio":
                  return (
                    <ConditonalRadioQuestionComponent
                      question={conditionalQuestion}
                    />
                  );
              }
            }
          });
        })}
      </Box>
    </Grid>
  );
}
export default ConditionalQuestions;
