const TARGET_PATH = "targetPath";

export const saveTargetPath = (path) => {
  saveToSessionStorage(TARGET_PATH, path);
};

export const getTargetPath = () => getFromSessionStorage(TARGET_PATH) || "/";

export const saveToSessionStorage = (k, v) => {
  window.sessionStorage.setItem(k, v);
};

export const clearSessionStorage = (k) => {
  window.sessionStorage.removeItem(k);
};

export const getFromSessionStorage = (k) => window.sessionStorage.getItem(k);

export const isLoggedIn = () => getFromSessionStorage("isLoggedIn") || false;
export const getAccessToken = () => getFromSessionStorage("access_token") || "";
export const getIdToken = () => getFromSessionStorage("id_token") || "";
export const getExpireBy = () => getFromSessionStorage("expire_by") || 0;
export const getPermissions = () => getFromSessionStorage("permissions") || "";
export const getEmail = () => getFromSessionStorage("userEmail") || "";
export const getCompanyName = () => getFromSessionStorage("company") || "";

export const setIsReferralUsers = (isReferralUsers) => {
  saveToSessionStorage("isReferralUsers", isReferralUsers);
};

export const setUserRefId = (UserRefId) => {
  saveToSessionStorage("userRefId", UserRefId);
};

export const setLoggedIn = (flag) => {
  saveToSessionStorage("isLoggedIn", flag);
};

export const setUserEmail = (flag) => {
  saveToSessionStorage("userEmail", flag);
};

export const setAccessToken = (access_token) => {
  saveToSessionStorage("access_token", access_token);
};

export const setIdToken = (id_token) => {
  saveToSessionStorage("id_token", id_token);
};

export const setExpireBy = (expire_by) => {
  saveToSessionStorage("expire_by", expire_by);
};

export const setPermissions = (permissions) => {
  saveToSessionStorage("permissions", permissions);
};

export const clearSession = () => {
  clearSessionStorage("isLoggedIn");
  clearSessionStorage("access_token");
  clearSessionStorage("id_token");
  clearSessionStorage("expire_by");
  clearSessionStorage("permissions");
};
