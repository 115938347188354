import { Auth } from "aws-amplify";
import { googleUserLogin } from "../stepper_form/stepper.helper";
import { useDispatch, useSelector } from "react-redux";
import { setUserStatus } from "../../redux/action/user";
import { getTargetPath, saveTargetPath } from "../../utils/storage";
import ReactGA4 from "react-ga4";
import axios from "axios";

export const useSupportEmails = () => {
  const emails = "maulikd@jspinfotech.com";
  return emails;
};

export const customGoogleUserLogin = async (
  dispatch,
  uniqueReferredUserId,
  type
) => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    // const referBy = useSelector((state) => state.userReducer.referBy);
    const referBy = localStorage.getItem("referBy");

    if (user.attributes.hasOwnProperty("sub")) {
      if (referBy !== null) {
        const payload = {
          email: user.attributes.email,
          registerTime: new Date(Date.now()).toUTCString(),
          lastLoginTime: new Date(Date.now()).toUTCString(),
          termsCondition: true,
          type: type,
          referBy: referBy,
          referTime: new Date(Date.now()).toUTCString(),
          uniqueReferredUserId,
        };
        await googleUserLogin(payload, dispatch, setUserStatus);
      } else {
        const payload = {
          email: user.attributes.email,
          registerTime: new Date(Date.now()).toUTCString(),
          lastLoginTime: new Date(Date.now()).toUTCString(),
          termsCondition: true,
          type: type,
          uniqueReferredUserId,
        };
        await googleUserLogin(payload, dispatch, setUserStatus);
      }
    }
  } catch (e) {
    console.log("e", e);
  }
};

const callBackUrl = (callBack, isParams, referralUrl) => {
  const isLocalhost = window.location.hostname === "localhost";

  if (callBack === "signIn") {
    if (isLocalhost) {
      if (isParams === referralUrl) {
        return "http://localhost:3000/endorse-expertise";
      } else {
        return `http://localhost:3000${isParams}`;
      }
    } else {
      if (isParams === referralUrl) {
        return "https://master.d3hqz1cwjn0bmv.amplifyapp.com/endorse-expertise";
      } else {
        return `https://master.d3hqz1cwjn0bmv.amplifyapp.com${isParams}`;
      }
    }
  } else {
    if (isLocalhost) {
      return "http://localhost:3000";
    } else {
      return `https://master.d3hqz1cwjn0bmv.amplifyapp.com`;
    }
  }
};

export const getRedirectSignInURL = () => {
  const NODE_ENV = process.env.REACT_APP_ENV;
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const redirectLink = params.get("link");
  if (redirectLink === "/endorse-expertise") {
    saveTargetPath(redirectLink);
  }
  const isParams = getTargetPath();
  const referralUrl = "/endorse-expertise";
  switch (NODE_ENV) {
    case "dev":
      return callBackUrl("signIn", isParams, referralUrl);

    case "test":
      if (isParams === referralUrl) {
        return "https://test.d1bdeo396g28nx.amplifyapp.com/endorse-expertise";
      } else {
        return `https://test.d1bdeo396g28nx.amplifyapp.com${isParams}`;
      }
    case "prod":
      if (isParams === referralUrl) {
        return "https://techworth.trueselfy.com/endorse-expertise";
      } else {
        return `https://techworth.trueselfy.com${isParams}`;
      }
    default:
      if (isParams === referralUrl) {
        return "https://techworth.trueselfy.com/endorse-expertise";
      } else {
        return `https://techworth.trueselfy.com${isParams}`;
      }
  }
};

export const getRedirectSignOutURL = () => {
  const NODE_ENV = process.env.REACT_APP_ENV;
  switch (NODE_ENV) {
    case "dev":
      return callBackUrl("signOut");
    case "test":
      return "https://test.d1bdeo396g28nx.amplifyapp.com";
    case "prod":
      return "https://techworth.trueselfy.com";
    default:
      return "https://techworth.trueselfy.com";
  }
};

export async function getCountryCode(setLoading) {
  try {
    // Get the user's public IP address
    // setLoading(true);
    const ipResponse = await axios.get("https://api.ipify.org/?format=json");
    const ipAddress = ipResponse.data.ip;

    // Fetch location data and device info concurrently
    const locationResponse = await Promise.all([
      fetch(`https://ipapi.co/${ipAddress}/json/`).then((response) =>
        response.json()
      ),
    ]);

    // Check if location data is valid and set it
    if (!locationResponse?.error) {
      setLoading(false);

      console.log(locationResponse, "locationResponse");
      return locationResponse;
    }
  } catch (error) {
    setLoading(false);

    console.log("Error:", error);
    // Handle specific errors here if needed
  }
}

export function GetUserLoginType() {
  const type = localStorage.getItem(
    `CognitoIdentityServiceProvider.${process.env.REACT_APP_COGNITO_CLIENT_ID}.LastAuthUser`
  );
  const userType = type?.split("_")[0];
  if (userType === "google") {
    return "google";
  } else if (userType === "linkedin") {
    return "linkedin";
  } else {
    return userType;
  }
}
