/** @format */

import React, { useState, useMemo, useEffect, Suspense } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import Authlogout from "./auth/Authlogout";
import AuthProvider from "./auth/AuthContext";
import ProtectedRoute from "./components/common/ProtectedRoute";
// import Loading from "./components/UI/Loading";
import HomePageV2 from "./pages/HomePage.V.0.2.js";
// import HomePageV2 from "./pages/HomePage.V.0.2";
import HomePageV3 from "./pages/HomePageV.0.3.js";
// import StepperForm from "./components/stepper_form/StepperForm";
import StepperForm from "./components/stepper_form/StepperForm";
import StepperFormV2 from "./components/stepper_form/StepperFormV.0.2";

import TechRelevance from "./pages/TechRelevance";
// import Result from "./pages/Result";
import UpdatedResultFinal from "./pages/UpdatedResultFinal";
// import Register from "./pages/Register";
import Login from "./pages/Login";
import LoginV3 from "./pages/LoginV0.0.3";
import toast, { Toaster, ToastBar } from "react-hot-toast";
// import NotFoundPage from "./components/UI/PageNotFound";

import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { HashRouter } from "react-router-dom";
// import { useSelector } from "react-redux";
// import ReactGA from "react-ga";
import Pricing from "./pages/Pricing";
import Refund from "./pages/Refund";
import ContactUs from "./pages/ContactUs";
import Evaluation from "./components/evaluation/Evaluation.js";
import "./App.css";
import ChangeLog from "./pages/ChangeLog";
import ChangeLogV2 from "./pages/ChangeLogV.0.2";
import TechRelevanceV2 from "./pages/TechRelevanceV.0.2";
import PreviousResult from "./pages/PreviousResult";
import PreviousResultV2 from "./pages/PreviousResult.V.0.2.js";
import PreviousResultV3 from "./pages/PreviousResult.V.0.4.js";
import ReferralPage from "./pages/ReferralPage.js";

import Result from "./pages/TechResultV.0.3.js";
import ResultV4 from "./pages/ResultV.0.0.4.js";
import MyFriends from "./pages/MyFriends.js";
import OldResult from "./pages/UpdatedResultFinal.js";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import AdminPanel from "./components/admin/AdminPanel.js";
import { getEmail } from "./utils/storage.js";
import { useDispatch, useSelector } from "react-redux";
import { setIncognitoMode } from "./redux/action/IncognitoMode.js";

// Define your custom breakpoints
// const customBreakpoints = {
//   xs: 0, // extra small devices (portrait phones, less than 600px)
//   sm: 600, // small devices (landscape phones, 600px and up)
//   md: 900, // medium devices (tablets, 900px and up)
//   lg: 1600, // large devices (desktops, 1200px and up)
//   xl: 1920, // extra large devices (large desktops, 1536px and up)
// };

const App = () => {
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  const New_Result_Show = useFeatureFlag("New_Result_Show");
  const New_Theme = useFeatureFlag("New_Theme");
  const Environment = process.env.REACT_APP_ENV;
  const theme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#26801E",
        border: New_Theme
          ? IncognitoModeStatus
            ? "#fff"
            : "#21175E"
          : "#26801E",
        evalute: "#2BB344",
        evalutebackground: "#EEFFF1",
        incognitoBackground: "#999999",
        incognitoText: "#fff",

        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#ffcc00",
      },
      error: {
        // light: "#0066ff",
        main: "#EF4F38",
        // dark: will be calculated from palette.secondary.main,
        // contrastText: "#ffcc00",
      },
      text: {
        primary: "#000",
        pricing: "#000",
      },
      // Provide every color token (light, main, dark, and contrastText) when using
      // custom colors for props in Material UI's components.
      // Then you will be able to use it like this: `<Button color="custom">`
      // (For TypeScript, you need to add module augmentation for the `custom` value)
      custom: {
        light: "#ffa726",
        main: "#f57c00",
        dark: "#ef6c00",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },

      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: `"Poppins", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            fontSize: "18px",
            // color: "#727272",
            color: "#232323",
            // "& h1,h2": {
            //   color: "black",
            // },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "16px",
            textTransform: "none",
            borderRadius: 28,
            padding: "8px 30px",
            minWidth: "unset",
          },
          contained: {
            // Targeting contained variant
            root: {
              fontSize: "16px",
              textTransform: "none",
              borderRadius: 28,
              padding: "8px 30px",
              minWidth: "unset",
              backgroundColor: New_Theme ? "#0D9B21" : "#26801E",
              color: "#fff",
              "&:hover": {
                backgroundColor: New_Theme ? "#35b848 !important" : "#fff",
              },
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            borderColor: New_Theme ? "#21175E" : "#26801E",
            "&:focus": {
              borderColor: New_Theme ? "#21175E" : "#26801E",
            },
            "&:active": {
              borderColor: New_Theme ? "#21175E" : "#26801E",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: New_Theme ? "#21175E" : "#26801E",
              borderWidth: "2px",
              color: "#000",
            },
            backgroundColor: "#fff", // Background color of the Select component
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired focus border color
            },
            "&:active": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired active border color
            },
            "&.MuiAutocomplete-option": {
              backgroundColor: "#fff", // Background color of the Select component
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired border color
            "&:focus": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired focus border color
            },
            "&:active": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired active border color
            },
          },
          popper: {
            "& .MuiPaper-root": {
              backgroundColor: "#fff", // Background color of the dropdown
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired border color
            "&:focus": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired focus border color
            },
            "&:active": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired active border color
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: New_Theme ? "#21175E" : "#26801E", // Change this to your desired color for focused label
            },
            color: "#000",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: New_Theme ? "#21175E" : "#26801E", // Change this to your desired color for radio buttons
          },
        },
      },
    },
    // breakpoints: {
    //   ...customBreakpoints,
    // },
  });
  theme.typography.h1 = {
    fontSize: "3rem",
    lineHeight: "3rem",
    "@media (max-width:600px)": {
      fontSize: "2.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "3rem",
    },
  };
  theme.typography.h2 = {
    fontSize: "2.125rem",
    lineHeight: "2.125rem",

    "@media (max-width:600px)": {
      fontSize: "1.625rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2rem",
    },
  };
  theme.typography.h3 = {
    fontSize: "1.75rem",
    lineHeight: "1.75rem",

    "@media (max-width:600px)": {
      fontSize: "1.375rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.6rem",
    },
  };
  theme.typography.h4 = {
    fontSize: "1.5rem",
    lineHeight: "2rem",

    "@media (max-width:600px)": {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  theme.typography.h5 = {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",

    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  theme.typography.h6 = {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",

    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };
  theme.typography.h6 = {
    fontSize: "1.2rem",
    lineHeight: "1.5rem",

    "@media (max-width:600px)": {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.4rem",
    },
  };

  const Admin_Access = useFeatureFlag("Admin_Access");
  const allowedEmails = JSON.parse(Admin_Access);
  const userEmail = getEmail();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const IncognitoMode = localStorage.getItem("IncognitoUser");
  //   if (IncognitoMode === "true") {
  //     dispatch(setIncognitoMode(true));
  //   } else {
  //     dispatch(setIncognitoMode(false));
  //   }
  // }, []);
  // console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        {/* <Header /> */}
        <BrowserRouter>
          <Routes>
            <Route
              path="/signin"
              element={New_Theme ? <LoginV3 /> : <Login />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute>
                  {New_Theme ? <HomePageV3 /> : <HomePageV2 />}
                </ProtectedRoute>
              }
            />
            <Route path="/changelog" element={<ChangeLog />} />
            {/* <Route path="/changelog-v2" element={<ChangeLogV2 />} /> */}

            <Route path="/tech-relevance" element={<TechRelevanceV2 />} />
            {/* <Route
                    path="/tech-relevance-v2"
                    element={<TechRelevanceV2 />}
                  /> */}

            <Route path="*" element={<Navigate to="/" />} />
            <Route
              path="/skills-calculation"
              element={
                <ProtectedRoute>
                  {New_Theme ? <StepperFormV2 /> : <StepperForm />}
                </ProtectedRoute>
              }
            />
            <Route
              path="/referrals"
              element={
                <ProtectedRoute>
                  <ReferralPage />
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/result/:_id"
              element={
                <ProtectedRoute>
                  {New_Theme ? <ResultV4 /> : <Result />}
                </ProtectedRoute>
              }
            />
            <Route
              exact
              path="/previous-result"
              element={
                <ProtectedRoute>
                  {IncognitoModeStatus ? (
                    New_Theme ? (
                      <HomePageV3 />
                    ) : (
                      <HomePageV2 />
                    )
                  ) : New_Theme ? (
                    <PreviousResultV3 />
                  ) : (
                    <PreviousResultV2 />
                  )}
                </ProtectedRoute>
              }
            />

            {/* <Route
              exact
              path="/pricing"
              element={
                <ProtectedRoute>
                  <Pricing />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              exact
              path="/refund-policy"
              element={
                <ProtectedRoute>
                  <Refund />
                </ProtectedRoute>
              }
            /> */}
            {/* <Route
              exact
              path="/my-friends"
              element={
                <ProtectedRoute>
                  {IncognitoModeStatus ? (
                    New_Theme ? (
                      <HomePageV3 />
                    ) : (
                      <HomePageV2 />
                    )
                  ) : (
                    <MyFriends />
                  )}
                </ProtectedRoute>
              }
            /> */}
            <Route
              exact
              path="/endorse-expertise"
              element={
                <ProtectedRoute>
                  {IncognitoModeStatus ? (
                    New_Theme ? (
                      <HomePageV3 />
                    ) : (
                      <HomePageV2 />
                    )
                  ) : (
                    <Evaluation />
                  )}
                </ProtectedRoute>
              }
            />
            {Environment === "dev" ? (
              <Route
                exact
                path="/trueselfy-admin"
                element={
                  <ProtectedRoute>
                    {allowedEmails.includes(userEmail) ? (
                      <AdminPanel />
                    ) : (
                      <>{New_Theme ? <HomePageV3 /> : <HomePageV2 />}</>
                    )}
                  </ProtectedRoute>
                }
              />
            ) : (
              ""
            )}
          </Routes>
        </BrowserRouter>
        <Toaster
          position="top-center"
          limit={1}
          toastOptions={{
            style: {
              wordBreak: "break-all",
              maxWidth: 800,
            },
          }}
        >
          {(t) => (
            <ToastBar
              toast={t}
              style={{
                // overflowWrap: "break-word",
                wordBreak: "break-all",
              }}
            >
              {({ icon, message }) => (
                <>
                  {icon}
                  {message}
                  {t.type !== "loading"}
                </>
              )}
            </ToastBar>
          )}
        </Toaster>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
