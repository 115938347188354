import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import Image from "@mui/material/Image";
export default function ResponsiveDialog({
  openResponsiveDialog,
  setResponsiveDialog,
  MaintenanceMessage,
}) {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/");
    setResponsiveDialog(false);
  };

  return (
    <React.Fragment>
      <Dialog open={openResponsiveDialog} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogContent>
          <Box
            sx={{ position: "relative", height: "218px", overflow: "hidden", textAlign: "left" }}
          >
            <img
              src={"../../assets/images/maintenance.svg"}
              alt="maintenance image"
              style={{
                position: "absolute",
                height: "38.73%",
                width: "82.2%",
                top: "9.17%",
                right: "8.73%",
                bottom: "52.1%",
                left: "9.07%",
                maxWidth: "100%",
                overflow: "hidden",
                maxHeight: "100%",
                objectFit: "cover",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                top: "116px",
                left: "50%",
                transform: "translateX(-50%)",
                fontWeight: 600,
              }}
            >
              We’re under Maintenance.
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "161px",
                left: "50%",
                transform: "translateX(-50%)",
                fontSize: "14px",
                lineHeight: "18px",
                color: "#636363",
                textAlign: "center",
              }}
            >
              <Typography variant="body1" className="were-busy-updating">
                {MaintenanceMessage}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", paddingTop: "8px" }}>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
