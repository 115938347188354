import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import PlaceIcon from "@mui/icons-material/Place";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colors = ["#FFC218", "#244392", "#FF8E09", "#196918", "#0C3CB8"];
  const colorIndex = Math.abs(hash) % colors.length;
  return colors[colorIndex];
}

function capitalizeFirstLetter(string) {
  return string[0].toUpperCase() + string.slice(1);
}

function stringAvatar(name) {
  return {
    sx: {
      width: { xs: 45, md: 80 },
      height: { xs: 45, md: 80 },
      marginRight: "18px",
      fontSize: { xs: 25, md: 50 },
      fontWeight: "500",
      bgcolor: stringToColor(name),
    },
    children: `${name?.split(" ")[0][0].toUpperCase()}`,
  };
}

function EvaluateUser({ user, isMobile, expandedPanel, index, evaluateuserLoader }) {
  return (
    <React.Fragment>
      <Grid
        container
        display={"flex"}
        alignItems={"center"}
        textAlign={"center"}
        // justifyContent={isMobile ? "flex-start" : "space-around"}
      >
        <Grid item xs={isMobile ? 10 : 6} sm={6} md={5} lg={4}>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Avatar {...stringAvatar(user?.evaluatedUserInfo?.name)} />

            <Box
              display={"flex"}
              flexDirection={`${isMobile ? "column" : "column"}`}
              flexWrap={"wrap"}
              alignItems={"left"}
              textAlign={"left"}
              pr={2}
            >
              {!isMobile && (
                <Typography variant="h3" sx={{ fontWeight: "600" }}>
                  {capitalizeFirstLetter(user?.evaluatedUserInfo?.name)}
                </Typography>
              )}
              {isMobile && (
                <>
                  <Typography
                    sx={{
                      wordBreak: "break-all",
                      overflowWrap: "break-word",
                      fontWeight: "600",
                    }}
                  >
                    {capitalizeFirstLetter(user?.evaluatedUserInfo?.name)}
                  </Typography>
                </>
              )}
              {isMobile && (
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
                  {/* <AccountCircleIcon sx={{ fontSize: "14px" }} /> */}
                  <Typography
                    sx={{
                      pl: 0.4,
                      fontStyle: "italic",
                      fontSize: `${isMobile ? "12px" : "20px"}`,
                    }}
                  >
                    {user?.evaluatedUserInfo?.role}
                  </Typography>
                </Box>
              )}
              {!isMobile && (
                <Typography
                  sx={{
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                    fontStyle: "italic",
                    fontSize: `${isMobile ? "12px" : "20px"}`,
                  }}
                >
                  {user?.evaluatedUserInfo?.role}
                </Typography>
              )}
              {isMobile && (
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    textAlign={"center"}
                    sx={{
                      display: "flex",
                      flexDirection: { sm: "column", md: "column", lg: "row" },
                      alignItems: "center",
                    }}
                  >
                    <ApartmentIcon size={"small"} sx={{ fontSize: "12px" }} />
                    <Typography sx={{ pl: 0.3, fontSize: "12px" }}>
                      {user?.evaluatedUserInfo?.currentCompany}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { sm: "column", md: "column", lg: "row" },
                      alignItems: "center",
                    }}
                  >
                    <PlaceIcon
                      sx={{
                        fontSize: "12px",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: "12px",
                      }}
                    >
                      {user?.evaluatedUserInfo?.location}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Grid>

        {!isMobile && (
          <Grid item xs={2}>
            <Box
              textAlign={"center"}
              sx={{
                display: "flex",
                flexDirection: { sm: "column", md: "column", lg: "row" },
                alignItems: "center",
              }}
            >
              <ApartmentIcon size={"medium"} />
              <Typography variant="h6" sx={{ pl: 0.7 }}>
                {user?.evaluatedUserInfo?.currentCompany}
              </Typography>
            </Box>
          </Grid>
        )}

        {!isMobile && (
          <Grid item xs={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { sm: "column", md: "column", lg: "row" },
                alignItems: "center",
              }}
            >
              <PlaceIcon />
              <Typography variant="h6">{user?.evaluatedUserInfo?.location}</Typography>
            </Box>
          </Grid>
        )}
        {/* {!isMobile && (
          <Grid item xs={2}>
            <Typography>{user?.lastModified}</Typography>
          </Grid>
        )} */}

        {expandedPanel !== index && (
          <>
            <Grid item xs={2}>
              {user?.evaluateuserLoader ? (
                <Box
                  sx={{
                    width: "100%",
                    display: `${isMobile ? "inline" : "flex"}`,
                    alignItems: "center",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                  {user?.formStatus === "pending" ? (
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                      <HourglassBottomOutlinedIcon sx={{ color: "#FF9D09" }} />
                      <Typography
                        variant="body1"
                        display={{ xs: "none", md: "block", lg: "block" }}
                      >
                        Pending
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                  {user?.formStatus === "success" ? (
                    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
                      <CheckCircleIcon sx={{ color: "green" }} />
                      <Typography
                        variant="body1"
                        display={{ xs: "none", md: "block", lg: "block" }}
                      >
                        Completed
                      </Typography>
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </React.Fragment>
  );
}

export default EvaluateUser;
