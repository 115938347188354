import { SET_QUESTION_OBJECT } from "../action/questionsObject";

export default function QuestionJsonReducer(state = "", action) {
  switch (action.type) {
    case SET_QUESTION_OBJECT: {
      const data = action.payload;
      state = data;
      return { state };
    }

    default:
      return state;
  }
}
