import { Grid } from "@mui/material";
import { useState } from "react";
import MultiSelectQuestionComponent from "./question-type-components/MultiSelectQuestionComponent";
import SkillMultiSelectQuestionComponent from "./question-type-components/SkillMultiSelectQuestionComponent";
import NumberQuestionComponent from "./question-type-components/NumberQuestionComponent";
import RadioQuestionComponent from "./question-type-components/RadioQuestionComponent";
import SelectQuestionComponent from "./question-type-components/SelectQuestionComponent";
import TextQuestionComponent from "./question-type-components/TextQuestionComponent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import "./StepperForm.css";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import DynamicMultiSelectQuestionComponent from "./question-type-components/DynamicMultiSelectQuestionComponent";
import PrimarySkillRelevanceAI from "./question-type-components/PrimarySkillRelevanceAI";
import { callDummyApi, googleUserLogin } from "./stepper.helper";
import { useDispatch, useSelector } from "react-redux";
import { setUserStatus } from "../../redux/action/user";
import { Auth } from "aws-amplify";
import { customGoogleUserLogin } from "../common/GlobalFunction";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { setIncognitoMode } from "../../redux/action/IncognitoMode";
import TooltipComponent from "../common/Tooltip";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";

// import useMediaQuery from "@mui/material/useMediaQuery";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 72,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    color: "#000",

    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: `${theme.palette.primary}`,
      transform: "translateX(32px)",
      "& .MuiSwitch-thumb:before": {
        // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        //   theme.palette.getContrastText(theme.palette.primary.main)
        // )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary,
    color: "#000",

    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //   "#26801E"
      // )}" d="M19,13H5V11H19V13Z" /></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

function SingleStepForm({ questionsObject, activeStepObject }) {
  const userEmail = localStorage.userEmail || "";
  const dispatch = useDispatch();
  const theme = useTheme();
  const New_Theme = useFeatureFlag("New_Theme");

  const Software_Personnel_Title = useFeatureFlag("Software_Personnel_Title");
  const IncognitoModeStatus = useSelector((state) => state.IncognitoMode?.IncognitoUser || false);
  const [IncognitoChecked, setIncognitoChecked] = useState(IncognitoModeStatus);
  const CustomTheme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: "#26801E",
        border: New_Theme ? (IncognitoModeStatus ? "#fff" : "#21175E") : "#26801E",
        evalute: "#2BB344",
        evalutebackground: "#EEFFF1",
        incognitoBackground: "#999999",
        incognitoText: "#fff",

        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#ffcc00",
      },
      error: {
        // light: "#0066ff",
        main: "#EF4F38",
        // dark: will be calculated from palette.secondary.main,
        // contrastText: "#ffcc00",
      },
      text: {
        primary: IncognitoModeStatus ? "#fff" : "#000",
        pricing: "#000",
      },
      // Provide every color token (light, main, dark, and contrastText) when using
      // custom colors for props in Material UI's components.
      // Then you will be able to use it like this: `<Button color="custom">`
      // (For TypeScript, you need to add module augmentation for the `custom` value)
      custom: {
        light: "#ffa726",
        main: "#f57c00",
        dark: "#ef6c00",
        contrastText: "rgba(0, 0, 0, 0.87)",
      },

      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: "#fff568 !important", // Set your desired color here
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            borderColor: New_Theme ? (IncognitoModeStatus ? "#fff" : "#21175E") : "#26801E",
            "&:focus": {
              borderColor: New_Theme ? (IncognitoModeStatus ? "#fff" : "#21175E") : "#26801E",
            },
            "&:active": {
              borderColor: New_Theme ? (IncognitoModeStatus ? "#fff" : "#21175E") : "#26801E",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: New_Theme ? (IncognitoModeStatus ? "#fff" : "#21175E") : "#26801E",
              borderWidth: "2px",
              color: IncognitoModeStatus ? "#fff" : "#000",
            },
            backgroundColor: IncognitoModeStatus ? "#999999" : "#fff", // Background color of the Select component
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            "&:focus": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired focus border color
            },
            "&:active": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired active border color
            },
            "&.MuiAutocomplete-option": {
              backgroundColor: IncognitoModeStatus ? "#999999" : "#fff", // Background color of the Select component
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          inputRoot: {
            borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired border color
            "&:focus": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired focus border color
            },
            "&:active": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired active border color
            },
          },
          popper: {
            "& .MuiPaper-root": {
              backgroundColor: IncognitoModeStatus ? "#a1a1a1" : "#fff", // Background color of the dropdown
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired border color
            "&:focus": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired focus border color
            },
            "&:active": {
              borderColor: New_Theme ? "#21175E" : "#26801E", // Change this to your desired active border color
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              color: IncognitoModeStatus ? "#fff" : New_Theme ? "#21175E" : "#26801E", // Change this to your desired color for focused label
            },
            color: IncognitoModeStatus ? "#fff" : "#000",
          },
        },
      },
    },
  });

  // useEffect(() => {
  //   customGoogleUserLogin(dispatch, uniqueReferredUserId);
  // }, []);
  useEffect(() => {
    /* Dummy Use Effect to active skill and certificate lambda */
    // Call the first function
    if (activeStepObject?.order < 4) {
      callDummyApi("/get-skills?skills=java");
      callDummyApi("/get-certificates?certificates=aws");
    }
  }, [activeStepObject.stepId]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [activeStepObject]);

  const handleChangeIncognitoMode = (event) => {
    setIncognitoChecked(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("IncognitoUser", true);
      dispatch(setIncognitoMode(true));
    } else {
      localStorage.setItem("IncognitoUser", false);
      dispatch(setIncognitoMode(false));
    }
  };
  return (
    <ThemeProvider theme={IncognitoModeStatus ? CustomTheme : theme}>
      <Box>
        {/* <Box display={"flex"} alignItems="center" justifyContent={"center"} sx={{ pb: 4 }}> */}
        {/* <img
          src="../../assets/images/india-flag.png"
          alt="indian-flag"
          style={{ maxWidth: "40px" }}
        /> */}
        {/* <Typography variant="h3" gutterBottom sx={{ mb: 0 }}>
          {Software_Personnel_Title}
        </Typography> */}
        {/* </Box> */}
        <Box
          sx={{
            position: "relative",
            // display: { xs: "none", sm: "flex" },
            // alignItems: "center",
            // justifyContent: { xs: "center", md: "space-between" },
            // pt: 12,
            // pb: 3,
            mb: "10px",
          }}
        >
          {activeStepObject.stepId === "step_8" ? (
            <></>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "space-between !important" }}>
              {/* <Box className="avatar">
              <Avatar
                className="avatar-img"
                src={`../../../../assets/images/pizza-active-icon/${activeStepObject.stepLabel.replace(
                  /\s+/g,
                  ""
                )}.svg`}
                sx={{ width: "80", height: "80" }}
              />
            </Box> */}
              <Box>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    lineHeight: "32px",
                    textAlign: { xs: "center", md: "left" },
                  }}
                  variant="h5"
                  component="h2"
                  // className="avatar-label"
                >
                  {activeStepObject.stepLabel}
                </Typography>
              </Box>
              <Box sx={{ display: "none", justifyContent: "center" }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Box>
                    <iconify-icon
                      display="flex"
                      alignItems="center"
                      height="1.5em"
                      icon="mdi:user"
                    ></iconify-icon>
                  </Box>
                  <FormControlLabel
                    sx={{ m: "0px !important" }}
                    control={
                      <MaterialUISwitch
                        checked={IncognitoChecked}
                        onChange={handleChangeIncognitoMode}
                        inputProps={{ "aria-label": "Incognito mode toggle" }}
                      />
                    }
                    // label={
                    //   <Typography variant="body1" style={{ fontWeight: "bold" }}>
                    //     {IncognitoChecked ? "Incognito Mode: On" : "Normal Mode: On"}
                    //   </Typography>
                    // }
                  />
                  <Box>
                    <iconify-icon
                      display="flex"
                      alignItems="center"
                      height="1.5em"
                      icon="mdi:incognito"
                    ></iconify-icon>
                  </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TooltipComponent
                    sx={{ pr: "5px" }}
                    size="small"
                    message={`What is Incognito Mode?
                            Incognito Mode is a feature that enhances your privacy by deleting your form data immediately after use, 
                            ensuring your personal information is protected. It also makes your data untraceable, 
                            You pay full price in Incognito Mode, You also cannot provide references for results/what if analysis done in this mode.
                            ("Incognito Mode is just the session.")`}
                  ></TooltipComponent>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {/* <Box>
        <Typography
          variant="p"
          display={"flex"}
          flexDirection="row"
          fontSize={"15px"}
          textAlign="right"
        >
          &nbsp;
          <p style={{ color: "red", fontWeight: "bold" }}>* &nbsp;</p> Fields are mandatory
        </Typography>
      </Box> */}

        <Box
          flex={1}
          sx={{
            pb: { xs: 2, md: 10, lg: 10, xl: 3 },
          }}
        >
          {questionsObject.map((question, index) => {
            if (
              question.stepId === activeStepObject.stepId &&
              !question.isSuggested &&
              !question.hideQuestion
            ) {
              switch (question.type) {
                case "text":
                  return (
                    <TextQuestionComponent
                      key={index}
                      question={question}
                      questionsObject={questionsObject}
                      index={index}
                    />
                  );
                case "number":
                  return (
                    <NumberQuestionComponent
                      key={index}
                      question={question}
                      questionsObject={questionsObject}
                    />
                  );
                case "select":
                  return (
                    <SelectQuestionComponent
                      question={question}
                      key={index}
                      questionsObject={questionsObject}
                    />
                  );
                case "multiSelect":
                  return (
                    <>
                      {question?.skillMultiselect === true ? (
                        <>
                          <SkillMultiSelectQuestionComponent question={question} key={index} />
                        </>
                      ) : question?.dynamicMultiSelect ? (
                        <DynamicMultiSelectQuestionComponent key={index} question={question} />
                      ) : (
                        <MultiSelectQuestionComponent question={question} key={index} />
                      )}
                    </>
                  );
                case "radio":
                  return (
                    <RadioQuestionComponent
                      question={question}
                      questionsObject={questionsObject}
                      key={index}
                    />
                  );
                default:
                  return "";
              }
            }
          })}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
export default SingleStepForm;
