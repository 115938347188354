// ** React Imports
import { useState, Fragment } from "react";

// ** Next Import
// import { useRouter } from "next/router";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Grid, IconButton, Modal } from "@mui/material";
import { Close, Logout } from "@mui/icons-material";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import { getEmail } from "../../utils/storage";
import { useSelector } from "react-redux";

const UserDropdown = ({ handleLogout, Evaluate_user, isLoggedIn }) => {
  const navigate = useNavigate();
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url) => {
    if (url) {
      navigate(url);
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openLogout, setOpenLogout] = useState(null);

  const handleModal = () => {
    setOpenLogout(true);
    setAnchorEl(null);
  };
  const handleCloseModal = () => {
    setOpenLogout(false);
    setAnchorEl(null);
  };

  const MenuItemStyled = styled(MenuItem)(() => ({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: "inherit",
    "& svg": {
      fontSize: "25px",
    },
    "&:hover ,&:hover svg": {
      color: `rgba(38,138,30) !important`,
    },
  }));

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        size="small"
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        disableRipple
        endIcon={<ArrowDropDownIcon />}
        sx={{
          display: { xs: "none", lg: "flex" },
          minWidth: "unset",
          padding: "0px",
          ml: 2,
          "& .MuiMenu-paper": { width: 230, mt: 4.75 },
          "&:hover": {
            background: "none",
          },
        }}
      >
        <Avatar
          alt={`${getEmail().toUpperCase()}`}
          src="/images/avatars/1.png"
          sx={{
            width: 38,
            height: 38,
            bgcolor: (theme) => theme.palette.primary.main,
          }}
        />
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            color: "#000 !important",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Avatar
                alt={`${getEmail().toUpperCase()}`}
                src="/images/avatars/1.png"
                sx={{ width: "2.5rem", height: "2.5rem", mr: 1 }}
              />
            </Badge>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2">{getEmail()}</Typography>
            </Box>
          </Box>
        </MenuItem>

        <Divider />
        {Evaluate_user && isLoggedIn && IncognitoModeStatus === false && (
          <MenuItemStyled
            onClick={() => handleDropdownClose("/endorse-expertise")}
          >
            <VerifiedOutlinedIcon fontSize="small" />
            Endorse Expertise{" "}
          </MenuItemStyled>
        )}
        {isLoggedIn && IncognitoModeStatus === false && (
          <MenuItemStyled onClick={() => handleDropdownClose("/referrals")}>
            <Diversity3Icon fontSize="small" />
            My References
          </MenuItemStyled>
        )}
        {/* {IncognitoModeStatus === false && (
          <MenuItemStyled onClick={() => handleDropdownClose("/my-friends")}>
            <ConnectWithoutContactIcon fontSize="small" />
            My Friends
          </MenuItemStyled>
        )} */}
        <Divider />

        <MenuItemStyled onClick={handleModal}>
          <Logout />
          Logout
        </MenuItemStyled>
      </Menu>
      <Modal
        open={openLogout}
        // onClose={handleClose}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 300,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography id="logout-modal-title" variant="h6" component="h2">
              Confirm Logout
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Grid>
          <Typography id="logout-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to logout?
          </Typography>
          <Grid container justifyContent="space-between" sx={{ mt: 4 }}>
            <Button
              sx={{ borderRadius: "55px" }}
              onClick={() => {
                handleLogout();
                setOpenLogout(false);
              }}
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
            <Button
              sx={{ borderRadius: "55px" }}
              onClick={handleCloseModal}
              variant="outlined"
              color="secondary"
            >
              No
            </Button>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default UserDropdown;
