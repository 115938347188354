// React Imports
import { useState } from "react";

// MUI Imports
import Grow from "@mui/material/Grow";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import { Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customAnchor: {
    top: "80%", // Customize the top position
    left: "35%", // Customize the left position
    [theme.breakpoints.down("sm")]: {
      top: "100px", // Adjust position for smaller screens if needed
      left: "10px",
    },
  },
}));

const GrowTransition = (props) => {
  return <Grow {...props} />;
};

const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const TrueTips = ({
  message,
  vertical,
  horizontal,
  state,
  setState,
  handleClose,
  autoHideDuration,
}) => {
  // States
  //   const [state, setState] = useState({
  //     open: false,
  //     Transition: Fade,
  //   });

  //   const handleClick = (Transition) => () => {
  //     setState({
  //       open: true,
  //       Transition,
  //     });
  //   };

  //   const handleClose = () => {
  //     setState({
  //       ...state,
  //       open: false,
  //     });
  //   };
  const classes = useStyles();

  return (
    <>
      <Snackbar
        open={state.open}
        onClose={handleClose}
        autoHideDuration={autoHideDuration}
        key={state.Transition.name}
        TransitionComponent={state.Transition}
        anchorOrigin={{ vertical, horizontal }}
        sx={{
          // position: "relative",
          // top: { xl: "3%" },
          // right: { md: "0vw", lg: "30vw", xl: "25vw" },
          zIndex: 9999,
        }}
        ContentProps={{
          className: classes.customAnchor,
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFC218",
            minHeight: "85px",
            p: 3,
            borderRadius: "18px",
            maxWidth: { md: "462px", lg: "380px", xl: "462px" },
            width: "100%",
            position: "relative",
            left: { md: "0vw", lg: "25%", xl: "48%" },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              textAlign: "center",
              backgroundColor: "#000",
              color: "#fff",
              px: 2,
              borderBottomLeftRadius: "8px",
              borderBottomRightRadius: "8px",
              top: 0,
              left: { xs: "34vw", sm: "38%", md: "38%", lg: "36%", xl: "40%" },
            }}
          >
            <Typography sx={{ p: "2px" }}>TrueTips</Typography>
          </Box>
          <Box sx={{ pt: 2.5 }}>
            <Typography variant="subtitle1" sx={{ textAlign: "center", lineHeight: "20px" }}>
              {message}
            </Typography>
          </Box>
        </Box>
      </Snackbar>
    </>
  );
};

export default TrueTips;
