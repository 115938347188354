import {
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Switch,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import TooltipComponent from "../../common/Tooltip";

function ConditonalRadioQuestionComponent({ question }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="stepper-question"
      >
        <Box>
          {question.label}
          {question.rules.required && (
            <>
              &nbsp;<span style={{ color: "red" }}>*</span>
            </>
          )}
        </Box>

        {question.question_info != null &&
        question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <>
            <TooltipComponent message={question?.question_info} />
          </>
        ) : (
          <></>
        )}
      </Typography>
      <Controller
        name={question.questionName}
        control={control}
        rules={question.rules}
        render={({ field }) => (
          <FormControl>
            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label">
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    onChange={(event, newValue) => {
                      field.onChange(newValue ? "Yes" : "No");
                    }}
                    checked={field.value === "Yes" ? true : false}
                  />
                }
                label="Yes"
              />
            </RadioGroup>
          </FormControl>
        )}
      />

      {errors[question.questionName]?.type === "required" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
    </Grid>
  );
}
export default ConditonalRadioQuestionComponent;
