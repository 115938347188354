import * as React from "react";
import {
  Grid,
  TextField,
  CircularProgress,
  Box,
  Alert,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useState, useEffect } from "react";
// import CancelIcon from "@mui/icons-material/Cancel";
import { Typography } from "@mui/material";
import validator from "validator";
import { useFormContext, Controller } from "react-hook-form";
// import axios from "axios";
import { toast } from "react-hot-toast";
// import TooltipComponent from "../common/Tooltip";
import { RUPEE } from "../common/GlobalVariable";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import Modal from "@mui/material/Modal";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import * as levenshtein from "damerau-levenshtein";
import { getEmail } from "../../utils/storage";
import { CloseRounded } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ShareIcon from "@mui/icons-material/Share";
import { useSelector, useDispatch } from "react-redux";
import { getReferralsStatus } from "../../components/common/getReferralsStatus";
import SecurityIcon from "@mui/icons-material/Security";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import { setIncognitoMode } from "../../redux/action/IncognitoMode";
import TooltipComponent from "../common/Tooltip";
// import incognitoIcon from "@iconify-icons/mdi/incognito";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#000",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        content: "''",
        // backgroundImage: `url('../../../assets/images/incognito-mode.svg')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    // backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    color: "#000",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
      //   "#000"
      // )}" d="M12 12c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm0 2c-3.33 0-10 1.67-10 5v2h20v-2c0-3.33-6.67-5-10-5z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: 500,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  overflow: "scroll",
  overflowX: "hidden",
  p: 4,
};

function StepReferals({
  setReferrals,
  questionsObject,
  step,
  discountedPrice,
  referralslist,
  setReferralDiscountPrice,
  results,
  setInvoiceDetails,
}) {
  const [ReferralsField, setReferralsField] = useState([
    { name: "", email: "", emailValid: "blank" },
  ]);
  // const { register } = useForm();
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    control,
    getValues,
    formState: { errors },
  } = useFormContext();
  const basePrice = useFeatureFlag("Base_price");
  const additionDiscount = useFeatureFlag("discount_value");
  // const discountedPrice = basaPrice - additionDiscount;
  let referralDiscount = useFeatureFlag("referralsDiscount");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const userEmail = getEmail();
  const demo_user_permission = useFeatureFlag("demo_user_permission");
  let userDiscount = 0;
  let userBasePrice = basePrice;
  const [referralsDiscountAmount, setRefferalsDiscountAmount] = useState(0);
  const Payment_Free_For_All = useFeatureFlag("Payment_Free_For_All");
  const Experienced_plan1_year_to_10_years = useFeatureFlag(
    "Experienced_plan1_year_to_10_years"
  );
  const Experienced_10_years = useFeatureFlag("Experienced_10_years");
  const minimum_price = useFeatureFlag("minimum_price");
  const referralFlag = useFeatureFlag("numberOfEvaluationResult");
  const FirstTimeFree = useFeatureFlag("FirstTimeFree");
  const theme = useTheme();
  const IncognitoModeStatus = useSelector(
    (state) => state.IncognitoMode?.IncognitoUser || false
  );
  const [IncognitoChecked, setIncognitoChecked] = useState(IncognitoModeStatus);
  const dispatch = useDispatch();

  const handleChangeIncognitoMode = (event) => {
    setIncognitoChecked(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("IncognitoUser", true);
      dispatch(setIncognitoMode(true));
    } else {
      localStorage.setItem("IncognitoUser", false);
      dispatch(setIncognitoMode(false));
    }
  };

  let experience = parseInt(getValues()?.experience);

  function compareEmails(userEmail, emailArray) {
    const threshold = 2; // Set a threshold for similarity

    for (let i = 0; i < emailArray.length; i++) {
      const email = emailArray[i];
      const distanceValue = levenshtein(userEmail, email).steps;
      if (distanceValue <= threshold) {
        return true; // User email is similar to at least one email in the array
      }
    }
    return false; // User email is not similar to any email in the array
  }

  function compareEmails(userEmail, emailArray) {
    const threshold = 2; // Set a threshold for similarity

    for (let i = 0; i < emailArray.length; i++) {
      const email = emailArray[i];
      const distanceValue = levenshtein(userEmail, email).steps;

      if (distanceValue <= threshold) {
        return true; // User email is similar to at least one email in the array
      }
    }

    return false; // User email is not similar to any email in the array
  }

  // add more referrals function
  const addReferralsField = () => {
    if (ReferralsField[ReferralsField?.length - 1].name.length !== 0) {
      if (
        ReferralsField[ReferralsField?.length - 1].email.length !== 0 &&
        ReferralsField[ReferralsField?.length - 1].emailValid
      ) {
        setReferralsField([...ReferralsField, { name: "", email: "" }]);
      } else {
        toast.error("Enter a valid Email");
      }
    } else {
      toast.error("Enter a Name");
    }
  };

  // remove referrals function
  const removeReferralsField = (index) => {
    const rows = [...ReferralsField];
    // console.log("rows");
    if (rows?.length > 1) {
      rows.splice(index, 1);
      setReferralsField(rows);
    }
  };

  // api for validate email via who xml

  // const whoxml = (value) => {
  //   try {
  //     return axios
  //       .get(
  //         `https://emailverification.whoisxmlapi.com/api/v2?apiKey=at_DjEQUcxKcuoLP7jd8FoEYJW3zWC91&emailAddress=${value}`
  //       )
  //       .then((res) => JSON.parse(res?.data?.smtpCheck));
  //   } catch (error) {
  //     return error.reponse;
  //   }
  // };

  //  referrals discount functionality start here
  function referralsDiscount(referrals) {
    let totalPrice = basePrice - referralDiscount * referrals;
    // console.log(totalPrice, "total price");
    return totalPrice;
  }
  const temp = [];
  ReferralsField?.map((referral) => {
    if (referral.email?.length > 0 && referral.emailValid) {
      temp.push(referral);
    }
  });

  const [feedbackQuestion, setFeedbackQuestion] = useState(null);
  useEffect(() => {
    questionsObject.map((question, index) => {
      if (question.stepId == step.stepId && !question.hideQuestion) {
        setFeedbackQuestion(question);
      }
    });
  }, []);

  function ExclusivePrice(exp) {
    if (exp > 10) {
      return Experienced_10_years;
    } else if (exp <= 10) {
      return Experienced_plan1_year_to_10_years;
    } else {
      return 0;
    }
  }

  const memoizedResults = new Map();

  function getBasePriceOnExperience(exp) {
    if (memoizedResults.has(exp)) {
      return memoizedResults.get(exp);
    }

    let discount =
      parseInt(
        (referralslist?.referrals?.filter((item) => item?.status === "success")
          ?.length || 0) * referralDiscount
      ) + parseInt(additionDiscount || 0);

    if (Payment_Free_For_All && !IncognitoModeStatus) {
      memoizedResults.set(exp, -1);
      return -1;
    }

    if (IncognitoModeStatus) {
      memoizedResults.set(exp, basePrice);
      return basePrice;
    }

    if (results === 0 && FirstTimeFree) {
      userBasePrice = basePrice;
      userDiscount = Experienced_10_years;
      memoizedResults.set(exp, -1);
      return -1;
    }

    userBasePrice = parseInt(basePrice);

    if (exp > 10) {
      userDiscount = Math.min(
        basePrice,
        basePrice - (parseInt(Experienced_10_years) - discount)
      );
    } else if (exp >= 1) {
      userDiscount = Math.min(
        basePrice,
        basePrice - (parseInt(Experienced_plan1_year_to_10_years) - discount)
      );
    } else {
      userDiscount = parseInt(basePrice);
      memoizedResults.set(exp, -1);
      return -1;
    }

    let price = userBasePrice - userDiscount;

    if (price < minimum_price) {
      price = minimum_price;
    }

    if (price <= 0) {
      if (minimum_price > 0) {
        price = minimum_price;
      } else {
        price = -1;
      }
    }

    memoizedResults.set(exp, price);
    return price;
  }

  function OffValue(off) {
    if (off < 0) {
      return 100;
    }
    let discount = ((basePrice - off) * 100) / basePrice;
    return discount.toFixed(2);
  }
  // function getbasePriceonExperience(exp) {
  //   if (exp >= 10) {
  //     let discount =
  //       parseInt(temp?.length * referralDiscount) + additionDiscount(additionDiscount);
  //     if (discount >= Experienced_10_years) {
  //       userDiscount = Experienced_10_years;
  //     } else {
  //       userDiscount = discount;
  //     }
  //     let price = Experienced_10_years - discount;
  //     if (price <= 0) {
  //       return "Free";
  //     } else {
  //       return RUPEE.format(price);
  //     }
  //   } else if ((exp >= 1) & (exp <= 10)) {
  //     let discount =
  //       parseInt(temp?.length * referralDiscount) + parseInt(additionDiscount);
  //     if (discount >= Experienced_plan1_year_to_10_years) {
  //       userDiscount = Experienced_plan1_year_to_10_years;
  //     } else {
  //       userDiscount = discount;
  //     }
  //     console.log("discount", discount);
  //     let price = Experienced_plan1_year_to_10_years - discount;

  //     if (price <= 0) {
  //       if (minimum_price > 0) {
  //         userDiscount = Experienced_plan1_year_to_10_years - minimum_price;
  //         return RUPEE.format(minimum_price);
  //       } else {
  //         return "Free";
  //       }
  //     } else {
  //       return RUPEE.format(price);
  //     }
  //   } else {
  //     return "Free";
  //   }
  // }

  const planPrice = getBasePriceOnExperience(experience);

  React.useEffect(() => {
    setReferralDiscountPrice(planPrice);
    // console.log("palnPrice", planPrice);
  }, [setReferralDiscountPrice, planPrice]);

  // domains
  const isMobile = useMediaQuery("(max-width:600px)");
  const domains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
    "jspinfotech.com",
  ];

  // handle change for email and name
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...ReferralsField];

    list[index][name] = value;
    setReferralsField(list);

    setReferrals(ReferralsField);
  };

  const handleChangeEmail = async (index, event) => {
    var { name, value } = event.target;
    const list = [...ReferralsField];
    list[index][name] = value.toLocaleLowerCase();
    var idx1 = list[index].email.indexOf("@");
    // var idx2 = list[index].email.index;

    if (idx1 > -1) {
      var splitStr = value.split("@");
      var sub = splitStr[1].split(".");

      if (domains.indexOf(splitStr[1]) < 0) {
        list[index].emailValid = false;
        setEmailError("Please enter only personal email IDs.");
      } else {
        if (
          validator.isEmail(value) &&
          ReferralsField[index].email != userEmail
        ) {
          list[index].emailValid = true;
        } else {
          list[index].emailValid = false;
          setEmailError(
            "Uh ho! That's an invalid email. Please enter a valid email id."
          );
        }
      }
    } else {
      list[index].emailValid = false;
      setEmailError(
        "Uh ho! That's an invalid email. Please enter a valid email id."
      );
    }
    if (ReferralsField[index].email === userEmail) {
      setEmailError(
        "Come on! Please enter a friend's email as a referral, not your own!"
      );
    }
    var emails = referralslist?.referrals?.map(function (item) {
      if (item.status !== "deleted") {
        // If the userStatus is not deleted, check if the email is equal to the value
        return item.email;
      }
    });
    const entredEmails = ReferralsField.slice(0, -1)?.map(function (item) {
      return item.email;
    });
    entredEmails.push(userEmail);
    // console.log("withuserEmail", withuserEmail);
    let similarEmail = compareEmails(ReferralsField[index].email, entredEmails);

    // console.log(withuserEmail, "entredEmails");

    // console.log(similarEmail, "similar");
    if (similarEmail) {
      list[index].emailValid = false;
      setEmailError("similar email entred. Please check ");
    }
    const founddup = entredEmails.find((ele) => ele === value);
    if (founddup == value) {
      list[index].emailValid = false;
      setEmailError("email already entred. Please check ");
    }
    // check email are already referred or not
    const found = emails.find((ele) => ele === value);

    if (found == value) {
      list[index].emailValid = false;
      setEmailError("email already referred. Please check referral list");
    }
    if (ReferralsField[index].email?.length <= 0) {
      list[index].emailValid = true;
      setEmailError(
        "Uh ho! That's an invalid email. Please enter a valid email id."
      );
    }
    setReferralsField(list);
    // console.log("ReferralsField Email", list[index].emailValid);
  };
  // setReferralDiscountPrice(data);
  useEffect(() => {
    setReferrals(ReferralsField);
  }, [setReferrals, ReferralsField]);
  let referrlaslength = referralslist?.referrals?.length;

  const userRefId = useSelector((state) => state.userReducer.userRefId || "");
  const hostname = window.location.hostname;
  // Split the hostname by '.' and get the first part
  const subdomainPart = hostname.split(".")[0];
  const baseURL =
    process.env.REACT_APP_ENV === "dev"
      ? "https://master.dd732pkswij8s.amplifyapp.com"
      : process.env.REACT_APP_ENV === "test"
      ? "https://test.d3si74wm7zgrqp.amplifyapp.com"
      : `https://${subdomainPart}.techvalue.trueselfy.com`; // Replace with your base URL
  const uniqueLink = `${baseURL}/signin?ref=${userRefId}&link=/endorse-expertise`; // Replace with your logic to generate the unique link

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(uniqueLink);
    toast.success("Copied");
  };

  const handleShareWhatsApp = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://wa.me/?text=${encodedLink}`);
  };

  const handleShareFacebook = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`);
  };

  const handleShareLinkedIn = () => {
    const encodedLink = encodeURIComponent(uniqueLink);
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`
    );
  };

  const actions = [
    {
      icon: <FileCopyOutlinedIcon />,
      name: "Copy",
      onClick: handleCopyToClipboard,
    },
    { icon: <WhatsAppIcon />, name: "WhatsApp", onClick: handleShareWhatsApp },
    { icon: <FacebookIcon />, name: "Facebook", onClick: handleShareFacebook },
    { icon: <LinkedInIcon />, name: "LinkedIn", onClick: handleShareLinkedIn },
  ];
  // set referral discount according to endorse referrals also set invoice details for payment

  useEffect(() => {
    setRefferalsDiscountAmount(
      parseInt(
        referralslist?.referrals?.filter((item) => item?.status === "success")
          ?.length * referralDiscount
      )
    );
    setInvoiceDetails({
      "base-price": parseInt(basePrice) || 0,
      "exclusive-price": parseInt(ExclusivePrice(experience)) || 0,
      "referrals-discount":
        parseInt(
          referralslist?.referrals?.filter((item) => item?.status === "success")
            ?.length * referralDiscount
        ) || 0,
      "total-discount-percentage": parseInt(OffValue(planPrice)) || 0,
      total: parseInt(planPrice) || 0,
    });
    // console.log("invoice", {
    //   "base-price": parseInt(basePrice) || 0,
    //   "exclusive-price": parseInt(ExclusivePrice(experience)) || 0,
    //   "referrals-discount":
    //     parseInt(
    //       referralslist?.referrals?.filter((item) => item?.status === "success")?.length *
    //         referralDiscount
    //     ) || 0,
    //   "total-discount-percentage": OffValue(planPrice) || 0,
    //   total: parseInt(planPrice) || 0,
    // });
    // console.log(typeof OffValue(planPrice), "typeof");
  }, [setRefferalsDiscountAmount, referralDiscount, referralslist]);

  return (
    <div>
      {/* <Grid
        display={"flex"}
        flexDirection={{
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        }}
      > */}

      {IncognitoModeStatus === false ? (
        <Typography
          variant="h2"
          component="h2"
          color="primary"
          sx={{ fontWeight: 600, mb: 3 }}
          textAlign="center"
        >
          Provide References !
        </Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            mt: "200px",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h2"
            component="h2"
            color={theme.palette.primary.incognitoText}
            sx={{ fontWeight: 600, mb: 3 }}
            textAlign="center"
          >
            Payment Details
          </Typography>
          <Typography
            variant="h1"
            pt={1}
            sx={{
              fontWeight: "bold",
              color: theme.palette.primary.incognitoText,
              textAlign: "center",
            }}
          >
            {planPrice > 0
              ? RUPEE.format(planPrice)
              : `${RUPEE.format(0)} Free`}
          </Typography>
          <Box sx={{ py: 2 }}>
            <Alert severity="info">
              You'r not eligible for discount in Incognito mode.
            </Alert>
          </Box>
        </Box>
      )}

      {/* </Grid> */}
      {feedbackQuestion && (
        <Grid pt={2}>
          <Typography
            variant="p"
            component="p"
            textAlign="left"
            sx={{ fontWeight: "bold" }}
          >
            Your feedback is important!
          </Typography>
        </Grid>
      )}

      <Grid container>
        {feedbackQuestion && (
          <Grid
            item
            xs={12}
            lg={12}
            md={12}
            my={1}
            // style={{ maxWidth: "50vw" }}
            // sx={{ pl: 13.5, pr: 4, pt: 3 }}
            alignItems="center"
            justifyContent={"center"}
            display={"flex"}
            flexDirection={"column"}
            // ml={3}
            // pl={{ xs: 4, md: 5, lg: 19 }}
            pr={{ xs: 3.5, md: 4.5, lg: 0 }}
            pt={1}
            // mr={3}
          >
            <Controller
              control={control}
              rules={feedbackQuestion.rules}
              name={feedbackQuestion.questionName}
              render={({ field }) => (
                <TextField
                  {...field}
                  style={{ width: "100%" }}
                  size="small"
                  label={feedbackQuestion.label}
                  variant="outlined"
                />
              )}
            />
            {errors[feedbackQuestion.questionName]?.type === "required" && (
              <p className={"errorMsg"}>{feedbackQuestion.errorMsg}</p>
            )}
          </Grid>
        )}
        <>
          {IncognitoModeStatus === false && (
            <>
              <Grid
                item
                textAlign={"center"}
                xs={12}
                pt={2}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                {referrlaslength > 0 ? (
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", md: "column", lg: "row" }}
                    alignItems={"center"}
                    justifyContent={"space-evenly"}
                    mb={2}
                  >
                    <Typography
                      textAlign={"justify"}
                      variant="h5"
                      pr={{ xs: 0, md: 0, lg: 4 }}
                      pb={{ xs: 1, md: 1, lg: 0 }}
                      sx={{ lineHeight: `${isMobile ? "30px" : "inherit"}` }}
                    >
                      Here's a list of all the amazing colleagues you've
                      provided as references who can validate your expertise.
                    </Typography>
                    <Button onClick={handleOpen} variant="contained">
                      View
                    </Button>
                  </Box>
                ) : (
                  ""
                )}
              </Grid>

              <Box>
                <Typography
                  variant="h5"
                  component={"h5"}
                  textAlign={"justify"}
                  sx={{
                    lineHeight: `${isMobile ? "30px" : "inherit"}`,
                    wordSpacing: isMobile ? "-1px" : "1px",
                  }}
                >
                  Let your colleagues endorse your expertise, thereby increasing
                  your authenticity, earn a
                  <span style={{ color: "#26801E", fontWeight: "bold" }}>
                    {" "}
                    “Peer reviewed”{" "}
                  </span>
                  badge and increase chances to earn more based on value adds
                  you bring to the table.
                  {/* {/ <TooltipComponent message={domains} /> /} */}
                </Typography>
              </Box>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: 2,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={`../../../assets/images/peerreviewed.svg`}
                    width={isMobile ? "110px" : "138px"}
                    height={"100%"}
                    alt="self-attested"
                    // className="self-attested-logo"
                  />
                </Box>
              </Grid>

              <Box>
                <Typography
                  variant="h5"
                  component="h5"
                  textAlign="justify"
                  sx={{ lineHeight: `${isMobile ? "30px" : "inherit"}`, mb: 2 }}
                >
                  Colleagues will be contacted by email, to ensure they provide
                  all the requisite details.
                </Typography>
                <Typography
                  variant="h5"
                  component="h5"
                  textAlign="left"
                  sx={{ lineHeight: "inherit" }}
                >
                  You need a minimum of {referralFlag} people to endorse your
                  expertise.
                </Typography>
              </Box>
              {/* </Grid> */}
              {/* <Alert severity="info" sx={{ m: "0px auto" }}>
          {" "}
          You need a minimum of {referralFlag} people to endorse your expertise.
        </Alert> */}
              {/* <Alert variant="outlined" severity="info" sx={{ m: "0px auto" }}>
          {" "}
          Ensure that you provide valid email addresses since an email will be sent to them!{" "}
        </Alert> */}
              {ReferralsField.map((item, index) => {
                return (
                  <>
                    <Grid
                      container
                      // spacing={3}
                      display={"flex"}
                      justifyContent="center"
                      alignItems="center"
                      sx={{ pt: 2 }}
                      // flexDirection="row"
                      key={index}
                      spacing={2}
                    >
                      <Grid
                        item
                        key={index}
                        alignItems="center"
                        justifyContent="center"
                        display={"flex"}
                        // lg={1}
                        // md={2}
                        xs={1}
                      >
                        <Typography fontWeight={"bold"}>{index + 1}</Typography>
                      </Grid>
                      {/* <Grid item xs={12} md={8} lg={10} sm={10}> */}
                      <Grid item xs={10}>
                        <Grid container spacing={{ xs: 1, sm: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              // style={{ width: "100%" }}
                              fullWidth
                              variant="outlined"
                              onChange={(event) => handleChange(index, event)}
                              label="Name"
                              placeholder="Enter Name"
                              multiline
                              name="name"
                              key={index}
                              value={item.name}
                              size="small"
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <Controller
                              control={control}
                              rules={{
                                validate: () => {
                                  return (
                                    ReferralsField[index].emailValid &&
                                    (ReferralsField[
                                      index
                                    ].email.toLocaleLowerCase() != userEmail
                                      ? (ReferralsField[
                                          index
                                        ].emailValid = true)
                                      : (ReferralsField[
                                          index
                                        ].emailValid = false))
                                  );
                                },
                              }}
                              name="email"
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  // xs={6}
                                  // lg={12}
                                  // md={6}
                                  size="small"
                                  fullWidth
                                  // style={{ width: "100%" }}
                                  onChange={(event) => {
                                    handleChangeEmail(index, event);
                                    // setValue(event.target.value);
                                  }}
                                  variant="outlined"
                                  label="Email"
                                  value={item.email}
                                  placeholder="Enter Email"
                                  required={
                                    ReferralsField[index].name?.length > 0
                                      ? true
                                      : false
                                  }
                                  // name="email"
                                  // id="email"
                                  // value={item.email}
                                  type="email"
                                  error={
                                    ReferralsField[index]?.emailValid === false
                                  }
                                  helperText={
                                    ReferralsField[index]?.emailValid === false
                                      ? emailError
                                      : ""
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <React.Fragment>
                                        {loading ? (
                                          <CircularProgress
                                            key={index}
                                            color="inherit"
                                            size={20}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </React.Fragment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          {/* </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        alignItems="center"
                        justifyContent="center"
                        display={"flex"}
                        // lg={1}
                        // md={2}
                        xs={1}
                        // sm={0.5}
                      >
                        {/* <DeleteIcon
                    color="error"
                    fontSize="medium"
                    style={{
                      cursor: "pointer",
                    }}
                    key={index}
                    onClick={() => removeReferralsField(index)}
                  /> */}
                        <IconButton
                          aria-label="delete"
                          size="large"
                          // disableRipple
                          // disableFocusRipple
                          disableTouchRipple
                          variant="outlined"
                          color="error"
                          onClick={() => removeReferralsField(index)}
                        >
                          {index > 0 || ReferralsField.length > 1 ? (
                            <DeleteOutlineRoundedIcon />
                          ) : (
                            ""
                          )}
                        </IconButton>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  pt={2}
                >
                  <Button
                    sx={{ fontSize: "smaller" }}
                    xs={12}
                    md={12}
                    variant="outlined"
                    size="small"
                    // variant="contained"
                    onClick={() => addReferralsField()}
                    // className="form-button"
                  >
                    Refer More +
                  </Button>

                  {/* <Typography pt={1} variant="p" className="referrals_notice" textAlign={"center"}>
              Ensure that you provide valid email addresses since an email will be sent to them.
            </Typography> */}
                  {/* <Alert severity="warning">
              {" "}
              Ensure that you provide valid email addresses since an email will be sent to them.!{" "}
            </Alert> */}
                </Box>
                <Box>
                  <Typography
                    textAlign={"center"}
                    fontWeight={700}
                    fontSize={"22px"}
                    sx={{ py: "15px" }}
                    color="primary"
                  >
                    OR
                  </Typography>
                </Box>
                {/* <Box
            sx={{
              position: "relative",
              // transform: "translateZ(0px)",
              flexGrow: 1,
              alignItems: "center",
              display: "flex",
              gap: { xs: 0, md: 2, lg: 3 },
              justifyContent: "center",
            }}
          >
            <Typography sx={{ pr: { xs: 6 } }}>
              Share this link so they can add the necessary details to endorse your expertise
            </Typography>
            <SpeedDial
              ariaLabel="SpeedDial basic example"
              sx={{
                position: "absolute",
                bottom: { xs: 0, sm: "-20px", lg: "-16px" },
                right: { xs: "-15px", sm: "-16px", md: "-20px", lg: 0 },
              }}
              icon={<ShareIcon />}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={action.onClick}
                />
              ))}
            </SpeedDial>
          </Box> */}
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    Provide references who can endorse and certify your
                    expertise.
                  </Typography>
                  <Typography
                    sx={{
                      color: "#888",
                      textAlign: "justify",
                      textAlign: "center",
                    }}
                  >
                    Share this link on any social media platforms such as
                    whatsapp, facebook, LinkedIn and don't forget to followup
                    with them.
                  </Typography>
                  {referralDiscount > 0 && !isNaN(experience) && (
                    <Box sx={{ py: "15px" }}>
                      <Typography
                        sx={{
                          color: "#888",
                          textAlign: "justify",
                          textAlign: "center",
                        }}
                      >
                        You may be eligible for discounts if people endorse your
                        expertise.
                      </Typography>
                      <Typography
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {referralDiscount > 0
                          ? `${RUPEE.format(
                              referralDiscount
                            )} Discount for each reference who endorses your expertise.`
                          : ""}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: {
                      xs: "wrap",
                      md: "nowrap",
                      lg: "nowrap",
                      xl: "nowrap",
                    },
                    flexDirection: "column",
                    gap: { xs: 2, xl: 2 },
                    alignItems: "center",
                    justifyContent: "center",
                    mb: { xs: 1, md: 3, lg: 5 },
                    mt: referralDiscount > 0 && !isNaN(experience) ? 0 : 2,
                  }}
                >
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    value={uniqueLink}
                    // size="small"
                    InputProps={{
                      readOnly: "true",
                      sx: { color: "#8D8D99" },
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      gap: { xs: 2, xl: 5 },
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleCopyToClipboard}
                    >
                      {" "}
                      Copy
                    </Button>
                  </Box>
                </Box>
              </Grid>
              {/* bill and discount part start here */}
              {/* <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-around"}
                p={2}
                flexDirection={{
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                }}
              >
                <Box
                  textAlign={"center"}
                  pt={experience > 1 && planPrice > 0 ? "" : { xs: 2, lg: 4 }}
                >
                  {IncognitoModeStatus === false && (
                    <Typography variant="h4" sx={{ textDecoration: "line-through" }}>
                      {userBasePrice > 0 ? `${RUPEE.format(userBasePrice)}` : ""}
                    </Typography>
                  )}
                  {IncognitoModeStatus === false && (
                    <Box
                      display={"flex"}
                      // flexDirection={"column"}
                      justifyContent={{
                        xs: "center",
                        md: "left",
                        lg: "left",
                      }}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="h6"
                        // color="rgba(38, 128, 30, 1)"
                        // sx={{ fontWeight: "bold" }}
                        pl={1}
                      >
                        {`${RUPEE.format(userDiscount)}`}
                      </Typography>
                      <Typography variant="subtitle1" color="error" sx={{ fontWeight: "bold" }}>
                        &nbsp;({OffValue(planPrice)}%) discount
                      </Typography>
                    </Box>
                  )}
                  <Typography
                    variant="h1"
                    pt={1}
                    sx={{ fontWeight: "bold", color: "rgba(38, 128, 30, 1)" }}
                  >
                    {planPrice > 0 ? RUPEE.format(planPrice) : `${RUPEE.format(0)} Free`}
                  </Typography>
                </Box>

                {experience >= 1 &&
                  referralsDiscountAmount >= 0 &&
                  planPrice < 0 &&
                  IncognitoModeStatus === false && (
                    <Box pt={1}>
                      <TableContainer>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell>Description</TableCell>
                              <TableCell>Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Base Price</TableCell>
                              <TableCell>&nbsp;{RUPEE.format(basePrice)}</TableCell>
                            </TableRow>
                            {Payment_Free_For_All && (
                              <TableRow>
                                <TableCell>Discount</TableCell>
                                <TableCell>&nbsp;{RUPEE.format(basePrice)}</TableCell>
                              </TableRow>
                            )}
                            {!Payment_Free_For_All && (
                              <TableRow>
                                <TableCell>Exclusive Price</TableCell>
                                <TableCell>
                                  &nbsp;
                                  {Payment_Free_For_All
                                    ? RUPEE.format(0)
                                    : RUPEE.format(ExclusivePrice(experience))}
                                </TableCell>
                              </TableRow>
                            )}
                            {referralDiscount > 0 && !Payment_Free_For_All ? (
                              <TableRow>
                                <TableCell>Max Referral Discount</TableCell>
                                <TableCell>
                                  -&nbsp;
                                  {RUPEE.format(
                                    referralsDiscountAmount > ExclusivePrice(experience)
                                      ? ExclusivePrice(experience)
                                      : referralsDiscountAmount
                                  )}
                                </TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            {additionDiscount > 0 ? (
                              <TableRow>
                                <TableCell>Additional Discount</TableCell>
                                <TableCell>-&nbsp;{RUPEE.format(additionDiscount)}</TableCell>
                              </TableRow>
                            ) : (
                              ""
                            )}
                            <TableRow>
                              <TableCell>Total</TableCell>
                              <TableCell>{RUPEE.format(0)}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Typography textAlign={"center"}>
                        (Based on{" "}
                        {!Payment_Free_For_All && referralsDiscountAmount > 0
                          ? " endorse referral"
                          : ""}{" "}
                        discount)
                      </Typography>
                    </Box>
                  )}
                {experience >= 1 && planPrice > 0 && IncognitoModeStatus === false && (
                  <Box pt={1}>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Description</TableCell>
                            <TableCell>Amount</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>Base Price</TableCell>
                            <TableCell>&nbsp;{RUPEE.format(basePrice)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Exclusive Price</TableCell>
                            <TableCell>&nbsp;{RUPEE.format(ExclusivePrice(experience))}</TableCell>
                          </TableRow>
                          {referralDiscount > 0 ? (
                            <TableRow>
                              <TableCell>Referral Discount</TableCell>
                              <TableCell>-&nbsp;{RUPEE.format(referralsDiscountAmount)}</TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )}
                          {additionDiscount > 0 ? (
                            <TableRow>
                              <TableCell>Additional Discount</TableCell>
                              <TableCell>-&nbsp;{RUPEE.format(additionDiscount)}</TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )}
                          <TableRow>
                            <TableCell>Total</TableCell>
                            <TableCell>{RUPEE.format(planPrice)}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Typography textAlign={"center"}>
                      (Based on {additionDiscount && "user discount and "}endorse discount)
                    </Typography>
                  </Box>
                )}
              </Grid> */}
            </>
          )}
        </>
      </Grid>
      {/* <Grid justifyContent={"center"}>
        <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", py: 2, alignItems: "center" }}>
            <Typography variant="body1" sx={{ textAlign: "center", fontWeight: "bold" }}>
              Normal Mode
            </Typography>
            <Box sx={{ pr: 0.5, py: 0.5, ml: "5px" }}>
              <iconify-icon
                display="flex"
                alignItems="center"
                height="1.5em"
                icon="mdi:user"
              ></iconify-icon>
            </Box>
          </Box>
          <FormControlLabel
            sx={{ ml: "16px !important" }}
            control={
              <MaterialUISwitch
                checked={IncognitoChecked}
                onChange={handleChangeIncognitoMode}
                inputProps={{ "aria-label": "Incognito mode toggle" }}
              />
            }
            
          />
          <Box sx={{ display: "flex", justifyContent: "center", py: 2, alignItems: "center" }}>
            <Box sx={{ pr: 0.5, py: 0.5, mr: "5px" }}>
              <iconify-icon
                display="flex"
                alignItems="center"
                height="1.5em"
                icon="mdi:incognito"
              ></iconify-icon>
            </Box>
            <Typography variant="body1" sx={{ textAlign: "center", fontWeight: "bold" }}>
              Incognito Mode
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TooltipComponent
              sx={{ pr: "5px" }}
              size="small"
              message={`What is Incognito Mode?
                            Incognito Mode is a feature that enhances your privacy by deleting your form data immediately after use, 
                            ensuring your personal information is protected. It also makes your data untraceable, 
                            You pay full price in Incognito Mode, You also cannot provide references for results/what if analysis done in this mode.
                            ("Incognito Mode is just the session.")`}
            ></TooltipComponent>
          </Box>
        </Box>
      </Grid> */}
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display={"flex"} flexDirection="row" justifyContent={"space-between"}>
            <Typography textAlign={"center"} pb={2}>
              Your References list
            </Typography>
            <HighlightOffTwoToneIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>No.</TableCell>
                  <TableCell>Email</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referralslist?.referrals?.map((row, index) => (
                  <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.email}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal> */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={800}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            // borderBottom: "1px solid #EBEBEB",
          }}
        >
          <Typography
            variant="h4"
            textAlign={"center"}
            sx={{ fontWeight: 500, lineHeight: 1.8 }}
          >
            Your References list
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseRounded />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TableContainer component={Paper} elevation={0}>
              <Table
                sx={{
                  size: { xs: "small", sm: "medium" },
                  minWidth: 250,
                  width: "100%",
                  maxWidth: 690,
                  border: "1px solid #D3D3D3",
                  borderCollapse: "separate",
                  overflow: "scroll",
                  borderRadius: "15px !important",
                  "& .MuiTableCell-head": {
                    fontSize: { xs: "15px", sm: "18px" },
                    fontWeight: 500,
                  },
                  "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root":
                    {
                      borderBottom: "0px",
                    },
                  "& .MuiTableCell-root h5": {
                    fontSize: { xs: "14px", sm: "18px" },
                    fontWeight: 400,
                  },
                  "& .MuiTableCell-root ": {
                    borderRight: "1px solid #D3D3D3",
                    padding: { xs: "8px !important", sm: "13px !important" },
                    textAlign: "center",
                  },
                  "& .MuiTableCell-root:last-child ": {
                    borderRight: "0px",
                    textAlign: "center",
                  },
                }}
                aria-label="simple table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Name</TableCell>

                    <TableCell>Email</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referralslist?.referrals?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography>{index + 1}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          varaint="h5"
                          component="h5"
                          sx={{ color: "#777777", textAlign: "left" }}
                        >
                          {row?.name}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          varaint="h5"
                          component="h5"
                          sx={{ color: "#777777", textAlign: "left" }}
                        >
                          {row.email}
                        </Typography>
                      </TableCell>
                      <TableCell> {getReferralsStatus(row?.status)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

export default StepReferals;
