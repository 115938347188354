import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import Container from "@mui/material/Container";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Header from "../components/Header";
import FooterComponent from "../components/Footer";
import { setUserResult } from "../redux/action/result";
import { List, ListItem, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import { setUserStatus } from "../redux/action/user";
import { Link } from "@mui/material";
import { ThemeContext } from "@emotion/react";
import { saveTargetPath } from "../utils/storage";
import { Auth } from "aws-amplify";
import { customGoogleUserLogin } from "../components/common/GlobalFunction";
import ResponsiveDialog from "../components/common/ResponsiveDialog";
import { KeyboardArrowRight } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CoverLetter from "../components/common/CoverLetter";
import { getPreviousResult } from "../components/stepper_form/stepper.helper";
import { Helmet } from "react-helmet";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { setupAuthInterceptor } from "../utils/apis";
import CustomizedSnackbars from "../components/common/CustomizedSnackbars";
import { OAuthCtx } from "../auth/AuthContext";

function HomePage() {
  const dispatch = useDispatch();
  const CalculateButtonValue = useFeatureFlag("Calculate_Button_text");
  const HomeMainTitle = useFeatureFlag("Home_Page_Main_Title");
  const HomePageDescription = useFeatureFlag("Home_Page_Description");
  const Referrals_mandatory_sentance = useFeatureFlag("_referrals_mandatory");
  const Software_Personnel_Title = useFeatureFlag("Software_Personnel_Title");
  const isMobile = useMediaQuery("(max-width:600px)");
  // const isLoggedIn = useSelector((state) => state?.userReducer?.isLoggedIn || false);
  const isLoggedIn = sessionStorage.getItem("isLoggedIn");
  const clickedSocialMediaLogin = sessionStorage.getItem("clickedSocialMediaLogin");

  const MaintenanceMessage = useFeatureFlag("Maintenance_Break");
  const [openResponsiveDialog, setResponsiveDialog] = React.useState(false);
  const [openTrueEdge, setOpenTrueEdge] = React.useState(false);
  const resultData = useSelector((state) => state?.ResultReducer?.previousResult);
  const { setAuthState } = React.useContext(OAuthCtx);
  const { getAuthState } = React.useContext(OAuthCtx);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMsg, setSnackbarMsg] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState();
  const Environment = process.env.REACT_APP_ENV;

  useEffect(() => {
    dispatch(setUserResult(""));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getPreviousResult(dispatch);
    }
  }, [isLoggedIn]);

  const checkUser = async () => {
    try {
      var currentSession = null;
      currentSession = await Auth.currentSession();
      // await setAuthState(currentSession);
      // setupAuthInterceptor(getAuthState);
      // dispatch(
      //   setUserStatus({
      //     isLoggedIn: sessionStorage.getItem("isLoggedIn"),
      //     userRefId: sessionStorage.getItem("userRefId"),
      //     uniqueReferredUserId: sessionStorage.getItem("uniqueReferredUserId"),
      //   })
      // );
      sessionStorage.setItem("clickedSocialMediaLogin", false);
    } catch (e) {
      setOpenSnackbar(true);
      setSnackbarSeverity("warning");
      setSnackbarMsg("you are not logged in");
      console.log("checkUser catch e", e);
    }
  };
  useEffect(() => {
    // customGoogleUserLogin(dispatch, uniqueReferredUserId);
    checkUser();
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    ReactGA4.send({
      hitType: "pageview",
      page: "/home",
      title: "visited Home",
    });
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  const navigate = useNavigate();
  const competencies = [
    "Technical skills",
    "Design and architecture",
    "Management and leadership",
    "Creativity and innovation",
    "Industry-specific expertise",
    "Interpersonal communication",
    "Process optimization",
    "Quality Assurance",
    "Product design and development",
    "Network Management and security",
    "Digitalization and Cloud computing",
    "AI and Machine learning",
  ];

  const reviewData = [
    {
      id: 0,
      name: "Rajdeep Singh",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7149663970931417088/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7149663970931417088%29",
      designation: "Chief Architect | Head of TAG (Technology Architecture Group",
      text: "TrueSelfy not only provides valuable tech trends but also delivers a comprehensive understanding of my technology skills' market worth. 🌐💰Kudos to Vucaware for trying out some thing different in similar space! 👏",
    },
    {
      id: 1,
      name: "Alok hota",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7127182559309926400/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7127182559309926400%29",
      designation: "Information Technology leader",
      text: "Just took a short survey from #TrueSelfy to gauge my TechWorth.. It was surprising to see the results close to a variance of 3% Awestruck !!",
    },
    {
      id: 2,
      name: "Sauneet Kumar",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7065216415657541632/?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7065216415657541632%29",
      designation: "Director at UBS",
      text: "Now you can recommend buddy professional connections in TrueSelfy.. beautifully done..",
    },

    {
      id: 3,
      name: "Prasana Iyengar",
      link: "https://www.linkedin.com/feed/update/urn:li:activity:7155038855568543744?updateEntityUrn=urn%3Ali%3Afs_feedUpdate%3A%28V2%2Curn%3Ali%3Aactivity%3A7155038855568543744%29",
      designation: "SRM at Google",
      text: "Valuable tool in these times where there's a lot of upheaval at employers with the uncertainty and promise of efficiency the chatgpt/GenAI has introduced for the last 15 months.",
    },
  ];

  return (
    <React.Fragment>
      {Environment === "prod" ? (
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16506783500"></script>
          <script>
            {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'AW-16506783500');
       `}
          </script>
        </Helmet>
      ) : (
        ""
      )}

      <Header />
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          // alignItems: "flex-end",
          alignItems: "flex-end",
          mt: { sm: "108px", sm: "70px", md: "80px", lg: "80px", xl: "56px" },
        }}
        spacing={2}
        p={{
          xs: "40px 20px",
          sm: "30px 50px",
          md: "30px 50px",
          lg: "40px",
          xl: "40px 80px 30px",
        }}
        // mt={{ lg: "50px" }}
      >
        <Grid item xs={12} lg={6}>
          <Box sx={{ ml: { lg: "52px" } }}>
            <Grid
              display={"flex"}
              alignItems="center"
              justifyContent={{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "flex-start",
              }}
              sx={{ pb: { xs: 1, lg: 2 } }}
              pt={{ xs: 7, sm: 0, md: 0, lg: 2 }}
              order={{ xs: 3, sm: 3, lg: 1 }}
              item
            >
              {/* <img
              src={"../../assets/images/india-flag.png"}
              style={{ minWidth: "60px" }}
              alt="india-flag"
            /> */}
              <Typography
                variant="h3"
                gutterBottom
                sx={{ mb: 0, textAlign: { xs: "center", md: "left" } }}
              >
                {Software_Personnel_Title}
              </Typography>
            </Grid>
            <Grid
              item
              order={{ xs: 1, sm: 1, lg: 2 }}
              justifyContent={{ xs: "center", md: "left", lg: "left" }}
            >
              <Typography
                sx={{ fontWeight: 600, mb: { xs: "20px", lg: "28px" } }}
                textAlign={{ xs: "center", lg: "left", md: "left" }}
                component="h1"
                variant="h1"
                // gutterBottom
              >
                {HomeMainTitle}
              </Typography>
            </Grid>
            <Grid
              item
              order={{ xs: 3, sm: 3, lg: 2 }}
              display={{ xs: "block", md: "block", lg: "block" }}
              textAlign={{ xs: "center", md: "left", sm: "left", lg: "left" }}
            >
              <Typography sx={{ fontSize: "24px", color: "#232323" }} variant="body1">
                {HomePageDescription}
              </Typography>
            </Grid>
            {Referrals_mandatory_sentance && (
              <Grid
                item
                order={{ xs: 2, sm: 2 }}
                display={"flex"}
                alignItems={{
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                }}
                flexDirection={"column"}
                justifyContent={{
                  xs: "center",
                  md: "center",
                  lg: "flex-start",
                  xl: "left",
                }}
                textAlign={{
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                }}
              >
                {Referrals_mandatory_sentance ? (
                  <Typography sx={{ mt: "10px", mb: "10px" }}>
                    Be ready with 3 referrrals prior to clicking "Calculate your worth" - You may
                    have to provide references to validate your expertise.
                  </Typography>
                ) : null}

                {/* <Button
              sx={{ mt: "50px", mb: "10px" }}
              variant="contained"
              onClick={() => {
                ReactGA.event({
                  category: "calculate",
                  action: "calculate-action",
                  label: "calculate-label",
                });
                if (MaintenanceMessage != "undefined") {
                  setResponsiveDialog(true);
                } else {
                  navigate("/skills-calculation");
                }
              }}
            >
              {CalculateButtonValue}
            </Button> */}
              </Grid>
            )}
            <Grid
              item
              xs={12}
              // sx={{
              //   display: "flex",
              //   flexWrap: "wrap",
              //   gap: 2,
              //   alignItems: "center",
              //   mt: "50px",
              //   mb: "20px",
              // }}
              // flexDirection={{ xs: "center", md: "center", lg: "flex-start" }}
              // justifyContent={"center"}
            >
              <Box
                display={"flex"}
                flexDirection={{ xs: "column", md: "column", lg: "row" }}
                // justifyContent={"space-around"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                sx={{ mt: "20px", mb: "20px" }}
              >
                <Box p={1}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      ReactGA.event({
                        category: "calculate",
                        action: "calculate-action",
                        label: "calculate-label",
                      });
                      if (MaintenanceMessage != "undefined") {
                        setResponsiveDialog(true);
                      } else {
                        navigate("/skills-calculation");
                        saveTargetPath(window.location.pathname);
                      }
                    }}
                  >
                    {CalculateButtonValue}
                  </Button>
                </Box>
                <Box p={1} sx={{ textAlign: { xs: "center", lg: "left" } }}>
                  <Link
                    // href={"/changelog"}
                    sx={{ cursor: "pointer", textAlign: { xs: "center", lg: "left" } }}
                    onClick={() => navigate("/changelog")}
                    color="primary"
                  >
                    TrueSelfy TechWorth Alogrithm Evolution
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Box>
          <Grid item xs={12}>
            <Box>
              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: 600,
                  mb: "15px",
                  mt: "0px",
                  ml: { xs: "0px", lg: "52px" },
                  textAlign: { xs: "center", md: "center", lg: "left" },
                }}
              >
                Read what users say.
              </Typography>
            </Box>
            <Box>
              <Swiper
                modules={[Autoplay, Navigation, Pagination]}
                spaceBetween={2}
                slidesPerView={1}
                breakpoints={{
                  438: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 50,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 50,
                  },
                }}
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: false,
                }}
                navigation={true}
                loop={true}
                scrollbar={{ draggable: true }}
                // centeredSlides="true"
                className="home-swiper"
              >
                {reviewData.map((item, index) => {
                  return (
                    <SwiperSlide key={item?.id}>
                      <Box
                        component="div"
                        sx={{
                          maxWidth: { xs: "394px", md: "500px", lg: "394px" },
                          width: { xs: "100%", lg: "100%" },
                          height: "100%",
                          boxSizing: "border-box",
                          background: "#FFF",
                          border: "1px solid #232323",
                          p: 2,
                          borderRadius: "24px",
                          // m: "0px 10px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              maxWidth: "24px",
                              width: "100%",
                              height: "auto",
                              borderRadius: "50%",
                              overflow: "hidden",
                              float: "right",
                              "& >img": {
                                width: "100%",
                              },
                            }}
                          >
                            <img src="../../assets/images/linkedin-rounded.png" alt="linkedin" />
                          </Box>

                          <Box
                            sx={{
                              maxWidth: "26px",
                              width: "100%",
                              margin: "0px auto",
                              overflow: "hidden",
                              mb: "15px",
                              "> img": { width: "100%" },
                            }}
                          >
                            <img src="../../assets/images/inverted-grey.png" alt="coma" />
                          </Box>
                          <Typography
                            className="clamp-text"
                            sx={{
                              fontSize: "14px",
                              color: "#000",
                              lineHeight: "18px",
                              mb: "10px",
                              textAlign: "center",
                            }}
                          >
                            {item?.text}
                          </Typography>
                          <Box
                            sx={{
                              width: "56px",
                              height: "2.5px",
                              backgroundColor: "#D7D7D7",
                              m: "0px auto",
                              mb: "10px",
                              display: "block",
                            }}
                          ></Box>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 600,
                                color: "#000",
                                textAlign: "center",
                                // mb: "2px",
                              }}
                            >
                              {item?.name}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                mb: "5px",
                                color: "#727272",
                              }}
                            >
                              {item?.designation}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Link
                            target="_blank"
                            href={item?.link}
                            // component="button"
                            sx={{
                              display: "inline-flex",
                              gap: 1,
                              alignItems: "center",
                              fontSize: "14px",
                              fontWeight: 400,
                              color: "#0D9B21",
                              textDecorationColor: "inherit",
                            }}
                          >
                            Read full review
                            <Box
                              component="span"
                              sx={{
                                width: "16px",
                                height: "16px",
                                display: "grid",
                                placeContent: "center",
                                background: "#0D9B21",
                                borderRadius: "12px",
                                color: "#fff",
                              }}
                            >
                              {" "}
                              <KeyboardArrowRight sx={{ fontSize: "16px" }} />{" "}
                            </Box>
                          </Link>{" "}
                        </Box>
                      </Box>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </Box>
          </Grid>
        </Grid>
        {/* <Grid
          xs={0}
          lg={1}
          md={0}
          display={{ xs: "none", md: "none", lg: "flex" }}
          flexWrap={"wrap"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <div
            style={{
              width: "1px",
              minHeight: "500px",
              height: "100%",
              maxHeight: "100%",
              border: "1px solid #727272",
            }}
          >
            {" "}
          </div>
        </Grid> */}
        <Grid item xs={12} lg={6} md={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              maxWidth: { lg: "590px ", xl: "700px" },
              width: "100%",
              m: "0px auto",
            }}
          >
            <img
              style={{ width: "100%" }}
              src={`../../assets/images/Pizza-Home-1.svg`}
              alt="pizza"
            />
          </Box>

          {isLoggedIn && resultData !== undefined && (
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row", lg: "column", xl: "row" },
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
                gap: 2,
                p: "0px 25px 4px 25px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // flexDirection: { xs: "column", sm: "row" },
                  justifyContent: { xs: "center", sm: "space-between" },
                  gap: 1,
                  background: "#0BA2FF",
                  maxWidth: "363px",
                  width: "100%",
                  p: "10px 12px",
                  borderRadius: "15px",
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: "#FFF",
                  textAlign: { xs: "center", md: "left" },
                  // "& .MuiButton-endIcon": {
                  //   ml: "0px !important",
                  // },
                }}
              >
                <Typography sx={{ fontWeight: "inherit" }}>Check Previous result </Typography>
                <Button
                  component="a"
                  variant="outlined"
                  endIcon={<KeyboardArrowRight />}
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "18px",
                    border: "1.5px solid #FFF",
                    borderRadius: "55px",
                    backgroundColor: "#0BA2FF",
                    pl: "12px",
                    pr: "8px",
                    color: "#FFF",
                    "&:hover": {
                      border: "1.5px solid #FFF",
                      color: "#FFF",
                      background: "rgb(255, 255, 255, 0.08)",
                    },
                    "& .MuiButton-endIcon": {
                      mr: "0px",
                      ml: "-2px",
                    },
                  }}
                  target="_blank"
                  href="/previous-result"
                >
                  Proceed
                </Button>
              </Box>
              {resultData?.coverLetter !== "" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // flexDirection: { xs: "column", sm: "row" },
                    justifyContent: { xs: "center", sm: "space-between" },
                    gap: 1,
                    background: "#0BA2FF",
                    maxWidth: "363px",
                    width: "100%",
                    p: "10px 12px",
                    borderRadius: "15px",
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: "24px",
                    color: "#FFF",
                    textAlign: { xs: "center", md: "left" },
                  }}
                >
                  <Typography sx={{ fontWeight: "inherit" }}>Check Previous TrueEdge </Typography>
                  <Button
                    variant="outlined"
                    endIcon={<KeyboardArrowRight />}
                    sx={{
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      border: "1.5px solid #FFF",
                      borderRadius: "55px",
                      backgroundColor: "#0BA2FF",
                      pl: "12px",
                      pr: "8px",
                      color: "#FFF",
                      "&:hover": {
                        border: "1.5px solid #FFF",
                        color: "#FFF",
                        background: "rgb(255, 255, 255, 0.08)",
                      },
                      "& .MuiButton-endIcon": {
                        mr: "0px",
                        ml: "-2px",
                      },
                    }}
                    onClick={() => setOpenTrueEdge(true)}
                  >
                    Proceed
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      {/* <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          backgroundImage: { xs: "none", lg: `url('../../assets/images/world-map.png')` },
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center left",
          backgroundSize: "auto",
        }}
        p={{ xs: "25px", sm: "30px 40px", lg: "30px 0px 30px 120px" }}
      >
        <Grid item xs={12} lg={3}>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Typography
              variant="h1"
              sx={{
                fontSize: "55px",
                fontWeight: 600,
                textAlign: { xs: "center", lg: "left" },
                mb: { xs: 3, lg: 0 },
              }}
            >
              Read what <br /> our users say.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Swiper
            modules={[Autoplay, Navigation, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={true}
            scrollbar={{ draggable: true }}
            centeredSlides="true"
            className="testimonial-swiper"
          >
            {reviewData.map((item, index) => {
              return (
                <SwiperSlide key={item?.id}>
                  <Box
                    component="div"
                    sx={{
                      maxWidth: { xs: "693px", lg: "unset" },
                      width: { xs: "100%", lg: "unset" },
                      height: "100%",
                      boxSizing: "border-box",
                      background: "#FFF",
                      border: "1.5px solid #2BB344",
                      px: { xs: 2, md: 4, lg: 8 },
                      py: { xs: 2, md: 3 },
                      borderRadius: "24px",
                      m: "0px auto",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: { xs: "40px", md: "52px" },
                        width: "100%",
                        height: "auto",
                        borderRadius: "50%",
                        overflow: "hidden",
                        float: "right",
                        "& >img": {
                          width: "100%",
                        },
                      }}
                    >
                      <img src="../../assets/images/linkedin-rounded.png" alt="linkedin" />
                    </Box>

                    <Box
                      sx={{
                        maxWidth: { xs: "55px", md: "82px" },
                        width: "100%",
                        margin: "0px auto",
                        overflow: "hidden",
                        mb: "15px",
                        "> img": { width: "100%" },
                      }}
                    >
                      <img src="../../assets/images/inverted-coma.png" alt="coma" />
                    </Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "16px", md: "18px" },
                        color: "#000",
                        mb: "20px",
                        textAlign: "center",
                      }}
                    >
                      {item?.text}
                    </Typography>
                    <Box
                      sx={{
                        width: "56px",
                        height: "4px",
                        backgroundColor: "#2BB344",
                        m: "0px auto",
                        mb: 3,
                        display: "block",
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        fontWeight: 600,
                        color: "#000",
                        textAlign: "center",
                        mb: "15px",
                      }}
                    >
                      {item?.name}
                    </Typography>
                    <Typography
                      sx={{ fontSize: "14px", textAlign: "center", color: "#636363", mb: "25px" }}
                    >
                      {item?.designation}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Button
                        // component="a"
                        target="_blank"
                        href={item?.link}
                        variant="contained"
                        endIcon={<ArrowForwardIosIcon />}
                      >
                        Read more
                      </Button>
                    </Box>
                  </Box>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Grid>
      </Grid> */}

      <Grid container>
        <Grid item xs={12}>
          <Box display={"flex"} justifyContent={"center"} id="techworth" sx={{ py: 2 }}>
            <img
              src={`../../assets/images/Trueselfy-parameters.svg`}
              style={{ maxWidth: "100%" }}
              alt="Trueselfy-parameters"
            />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#F4F4F4",
        }}
        flexDirection={{ xs: "column", md: "column", lg: "row" }}
        justifyContent={{ xs: "center", md: "center", lg: "space-evenly" }}
        p={{ xs: "10px 20px", sm: "0px 50px", md: "0px 50px", lg: "40px 80px" }}
      >
        <Grid
          item
          xs={12}
          lg={4}
          sx={{
            mt: { md: "20px" },
          }}
        >
          <Box>
            <Typography
              variant="h3"
              component="h3"
              sx={{ fontWeight: 600, color: "black" }}
              pb={3}
              textAlign={{ xs: "center", md: "center", sm: "left", lg: "left" }}
            >
              How your TechWorth is calculated?
            </Typography>
          </Box>
          <Box pb={3} xs={12}>
            <img
              style={{ maxWidth: "100%" }}
              src={`../../assets/images/techworth-new.svg`}
              alt="techworth"
            />
          </Box>
          <Box xs={12}>
            <img
              style={{ maxWidth: "100%" }}
              src={`../../assets/images/tevalue&marketsalary.svg`}
              alt="techvalue&marketsalary"
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Box sx={{ ml: { lg: 6 } }}>
            <img
              style={{ maxWidth: "100%" }}
              src={`../../assets/images/techworthdial.svg`}
              alt="techworthdial"
            />
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "10px 40px",
              borderRadius: "12px",
              flexDirection: "column",
              mt: { lg: "40px" },
              mb: { md: "20px" },
            }}
            display={{
              xs: "flex",
              md: "flex",
              sm: "flex",
              lg: "block",
              xl: "block",
            }}
            alignItems={{ xs: "center", md: "center", lg: "left", xl: "left" }}
          >
            {[
              { label: "Technology skills", count: "5000+" },
              { label: "Technology Management skills", count: "750+" },
              { label: "Functional/domain skills", count: "1000+" },
              { label: "Generic skills", count: "30000+" },
            ].map((item, index) => (
              <Box
                display={"flex"}
                alignItmes={"center"}
                key={index}
                textAlign={{
                  xs: "center",
                  md: "center",
                  sm: "center",
                  lg: "left",
                }}
                py={{ xs: 1, md: 1, sm: 1, lg: 0.5 }}
                // sx={{
                //   display: "flex",
                //   flexDirection: "row",
                //   alignItems: "center",
                // }}
              >
                <Box display={{ xs: "none", md: "none", lg: "block" }}>
                  <span className="custom-border"></span>
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "30px" }}>
                    <mark>{item?.count} </mark>
                  </Typography>
                  <Typography sx={{ fontSize: "22px" }}>{item?.label}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
        p={{
          xs: "30px 20px",
          sm: "30px 50px",
          md: "30px 50px",
          lg: "50px 120px",
        }}
      >
        <Box>
          <Typography
            variant="body1"
            component="h4"
            textAlign={{
              xs: "center",
              md: "justify",
              lg: "justify",
            }}
            sx={{
              color: "#232323",
            }}
            fontSize={{ xs: "24px", md: "24px", lg: "24px" }}
          >
            TrueSelfy’s innovative algorithm offers comprehensive evaluation of <mark>5000+ </mark>
            technology skills,<mark> 750+</mark> technology management skills, <mark>1000+</mark>{" "}
            functional/domain skills, and <mark>30000+</mark> generic skills to determine the
            current market value of your expertise. TrueSelfy considers macroeconomic impact,
            technological advancements and obsolescence, and is continuously updated with new
            competencies so that the insights you get are always current.
          </Typography>
        </Box>
      </Grid>
      <Grid
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{
          position: "relative",
          top: isMobile ? 40 : 70,
          padding: isMobile ? 1 : 3,
        }}
      >
        <Typography
          // variant="h4"
          textAlign={"center"}
          sx={{
            fontWeight: "bold",
            backgroundColor: "#2BB344",
            padding: `${isMobile ? "4px 0px" : "14px 20px"}`,
            color: "white",
            borderRadius: "50px",
            fontSize: `${isMobile ? "16px !important" : "34px"}`,
          }}
        >
          TrueSelfy is designed for all types of competencies
        </Typography>
      </Grid>
      <Grid
        container
        spacing={1.5}
        sx={{ mb: 5 }}
        textAlign={{ xs: "center", md: "center", lg: "center" }}
        // alignItems="center"

        p={{
          xs: "0px 8px 0px 20px",
          sm: "0px 28px 0px 40px",
          md: "10px 50px",
          lg: "20px 80px 20px 100px",
        }}
      >
        <Box
          display={"flex"}
          flexWrap={"wrap"}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{
            border: "2.5px solid #2BB344",
            borderRadius: "12px",
            padding: "50px 20px",
          }}
        >
          {competencies?.map((item, index) => (
            <Grid item xs={12} lg={4} md={4} key={index}>
              <List sx={{ padding: "0px !important" }}>
                <ListItem sx={{ padding: "0px !important" }}>
                  <ListItemIcon>
                    {/* <CheckCircleIcon
                      sx={{
                        color: "#2BB344",
                        mr: 0.5,
                        fontSize: "32px !important",
                      }}
                    /> */}
                    <img src={`../../assets/images/home-icons/${index}.svg`} alt={`${item}`} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="h4" component="h4" sx={{ color: "#232323" }}>
                      {item}
                    </Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Grid>
          ))}
        </Box>
        <CoverLetter setOpen={setOpenTrueEdge} open={openTrueEdge} data={resultData} />
      </Grid>
      {openResponsiveDialog && (
        <ResponsiveDialog
          openResponsiveDialog={openResponsiveDialog}
          setResponsiveDialog={setResponsiveDialog}
          MaintenanceMessage={MaintenanceMessage}
        />
      )}

      <FooterComponent />
      {clickedSocialMediaLogin && (
        <CustomizedSnackbars
          openSnackbar={openSnackbar}
          setOpenSnackbar={setOpenSnackbar}
          snackbarMsg={snackbarMsg}
          snackbarSeverity={snackbarSeverity}
        />
      )}
    </React.Fragment>
  );
}

export default HomePage;
