import React from "react";
import { useTheme } from "@mui/material/styles";
// import ReactApexcharts from "src/@core/components/react-apexcharts";
import ReactApexcharts from "react-apexcharts";

function PopularityChart({ value }) {
  const theme = useTheme();

  const options = {
    // responsive: true,
    chart: {
      sparkline: { enabled: true },
    },
    stroke: { lineCap: "round" },
    //   colors: [hexToRGBA(theme.palette.warning.main, 1)],
    colors: ["#26801E"],
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    plotOptions: {
      radialBar: {
        endAngle: 90,
        startAngle: -90,
        hollow: { size: "64%" },
        track: {
          strokeWidth: "100%",
          // background: hexToRGBA(theme.palette.customColors.trackBg, 1),
          //   background: "green",
        },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: -3,
            fontWeight: 600,
            color: "#008",
            formatter: (val) => `${val / 10}/10`,
            fontFamily: theme.typography.fontFamily,
            fontSize: "35px",
          },
        },
      },
    },
    grid: {
      padding: {
        bottom: 15,
      },
    },
    responsive: [
      {
        breakpoint: theme.breakpoints.values.lg,
        options: {
          chart: { height: 230 },
        },
      },
      {
        breakpoint: 430,
        options: {
          chart: { height: 230 },
        },
      },
    ],
  };

  return (
    <>
      <ReactApexcharts type="radialBar" height={250} series={[value]} options={options} />
    </>
  );
}

export default PopularityChart;
