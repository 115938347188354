/** @format */

import * as React from "react";
import { useState, useRef } from "react";
// import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
// import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import CardHeader from "@mui/material/CardHeader";
// import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
// import StarIcon from "@mui/icons-material/StarBorder";
// import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Link from "@mui/material/Link";
// import GlobalStyles from "@mui/material/GlobalStyles";
// import Container from "@mui/material/Container";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Avatar from "@mui/material/Avatar";
import "./UpdatedResult.css";
// import zIndex from "@mui/material/styles/zIndex";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROUNDIGIT, RUPEE } from "../components/common/GlobalVariable";
import { getEmail } from "../utils/storage";
// import _ from "lodash";
import { useEffect } from "react";
import ReactGA from "react-ga";
import ReactSpeedometer from "react-d3-speedometer";
import TooltipComponent from "../components/common/Tooltip";
import { Alert, Link } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import OpenAI from "openai";
import LoadingButton from "@mui/lab/LoadingButton";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import toast from "react-hot-toast";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { getCoverLeter } from "../components/stepper_form/stepper.helper";
import Divider from "@mui/material/Divider";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 3,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#2BB344",
  },
}));

// import apis from "../utils/apis";

function PreviousResult() {
  const resultData = useSelector((state) => state?.ResultReducer.previousResult || "");
  const userForm = useSelector((state) => state?.ResultReducer?.previousResult?.userData || "");
  const navigate = useNavigate();
  const staticRandomEncodeDecodeString = useFeatureFlag("staticRandomEncodeDecodeString");
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const isMobile = useMediaQuery("(max-width:600px)");
  const openai = new OpenAI({
    apiKey: "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", // defaults to process.env["OPENAI_API_KEY"]
    dangerouslyAllowBrowser: true,
  });
  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
    setText("");
  };
  const handleClose = () => setOpen(false);
  const [text, setText] = useState("");
  const Chatgpt_promp_for_cover_letter = useFeatureFlag("Chatgpt_promp_for_cover_letter");
  const User_Cover_letter = useFeatureFlag("User_Cover_letter");

  const handleChange = (event) => {
    const selectionStart = event.target.selectionStart;
    const selectionEnd = event.target.selectionEnd;

    // Get the current value of the text area.
    const currentValue = event.target.value;

    // Get the text that the user is typing.
    const newText = event.target.value.substring(selectionStart, selectionEnd);

    // Replace the selected text with the new text.
    const newValue = currentValue.replace(
      currentValue.substring(selectionStart, selectionEnd),
      newText
    );
    setText(newValue);
  };

  const handleCopyToClipboard = () => {};

  const [openClipboard, setOpenClipboard] = useState(false);

  const handleClick = () => {
    toast.success("Copied");
  };

  function removeEmptyKeysAndObjects(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          obj[key] === null ||
          obj[key] === undefined ||
          obj[key] === "" ||
          obj[key] === "No" ||
          key === "userlocation" ||
          key === "resultTime" ||
          key === "lastStepper" ||
          key === "deviceinfo" ||
          key === "location" ||
          key === "typeOfCompany"
        ) {
          delete obj[key];
        } else if (typeof obj[key] === "object") {
          if (Array.isArray(obj[key])) {
            if (obj[key].length === 0) {
              delete obj[key];
            } else {
              obj[key].forEach((item) => {
                if (item && typeof item === "object" && "type" in item) {
                  delete item.type;
                }
              });
            }
          } else {
            if (Object.keys(obj[key]).length === 0) {
              delete obj[key];
            }
          }
        }
      }
    }
    return obj;
  }

  function DataComponent() {
    return (
      <div>
        <textarea className="typing-textarea" value={text} onChange={handleChange} />
      </div>
    );
  }

  const getBiolater = async () => {
    // console.log("removeObjects", removeEmptyKeysAndObjects(userForm));
    handleOpen();
    const techValue = numDifferentiation(
      resultData?.results?.techValue - (resultData?.results?.techValue % ROUNDIGIT) || 0
    );
    // console.log("techValue", techValue);
    let userEmail = getEmail();
    let userData = removeEmptyKeysAndObjects(userForm);
    let userObject = {
      userData,
      techValue,
      userEmail,
    };

    getCoverLeter(userObject, setText, setLoading);
    // console.log("text", text);
  };

  const allSkills = resultData?.primarySkill?.concat(resultData?.secondarySkill)?.map((item) => {
    return item.name;
  });
  // const date = new Date(Date.now()).toString();
  useEffect(() => {
    if (resultData?.results?.techValue === undefined) {
      // console.log("works");
      navigate("/");
    }
  }, [navigate, resultData.name, resultData?.techValue]);
  function numDifferentiation(value) {
    const val = Math.abs(value);
    if (val >= 10000000) return `${RUPEE.format((value / 10000000).toFixed(2))} Crore`;
    if (val >= 100000) return `${RUPEE.format((value / 100000).toFixed(2))} Lakhs`;
    return `${RUPEE.format(value)}`;
  }
  function segmentValueFormatter(value) {
    if (value < 0) {
      return `$😒`;
    }
    if (value < 1) {
      return `$😐`;
    }
    if (value < 3) {
      return `$😌`;
    }
    if (value < 4) {
      return `$😊`;
    }
    if (value < 5) {
      return `$😉`;
    }
    return `${value} 😇`;
  }
  return (
    <React.Fragment>
      <Header />

      <Grid
        display={{ xs: "block", lg: "none", md: "none" }}
        // xs={12}
        // sm={6}
        order={{ xs: 1, sm: 2 }}
      >
        <Box>
          {/* <img
            style={{
              maxWidth: "98px",
              top: "205px",
              left: "50px",
              zIndex: -1,
              position: "absolute",
            }}
            src="../../../assets/images/EllipseRed.png"
            alt="Ellipse"
            className="ellipse-red"
          /> */}
          <img
            style={{ maxWidth: "100%" }}
            src="../../../assets/images/pizza-box.svg"
            alt="pizza-box"
          />
          {/* <img
            style={{
              maxWidth: "98px",
              top: "250px",
              left: "230px",

              zIndex: -1,
              position: "absolute",
            }}
            className="ellips-blue"
            src="../../../assets/images/EllipseBlue.png"
            alt="Ellipse"
          /> */}
        </Box>
      </Grid>
      {/* Hero unit */}
      {/* <Grid
        textAlign={"center"}
        justifyContent="center"
        display={"flex"}
        flexDirection={"column"}
      > */}
      <Box>
        <Box sx={{ py: 2 }}>
          <Typography
            variant="h1"
            textAlign={"center"}
            sx={{
              fontWeight: 600,
            }}
            color="#26801E"
          >
            {`${resultData?.name?.trim()}'s TechWorth`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            pb: { xs: 2, sm: 2, md: 0, lg: 0 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              alignItems: "center",
              p: { xs: 0, lg: 1 },
            }}
          >
            <Typography variant="h4" textAlign="center" color={"primary"} sx={{ fontWeight: 700 }}>
              Result Id :
            </Typography>
            <Typography variant={"h5"} sx={{ padding: { xs: 0, lg: 1 } }}>
              {resultData?._id}
            </Typography>
          </Box>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              p: { xs: 0, lg: 1 },
              alignItems: "center",
            }}
          >
            <Typography
              color={"primary"}
              variant={"h4"}
              sx={{ fontWeight: 700, pl: { xs: 0, lg: 2 } }}
            >
              Location :
            </Typography>
            <Typography variant={"h5"} sx={{ p: { xs: 0, lg: 1 } }}>
              {userForm?.location}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* </Grid> */}
      <Grid
        // Container
        maxWidth="auto"
        component="main"
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"center"}
        sx={{ p: 1, backgroundColor: "#F3FFEB" }}
      >
        <Grid
          // className="center"
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          order={{ xs: 2, sm: 1 }}
          justifyContent={{ xs: "center", md: "center", lg: "center" }}
          alignItems={"center"}
        >
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
            }}
            justifyContent={{
              xs: "center",
              md: "center",
              lg: "flex-start",
            }}
            ml={{ xs: 0, md: 5, lg: 2, xl: 22 }}
            mt={{ sm: 3 }}
            p={2}
          >
            <Box
              // sx={{ pr: 8 }}\
              ml={{ xs: 0, md: 0, lg: 0 }}
              pl={{ lg: 13, sm: 12, md: 12, xs: 8 }}
              border={"2px solid"}
              borderRadius={"100px"}
              display={"flex"}
              position={"relative"}
              justify-content={"center"}
              backgroundColor={"#2BB344"}
              alignItems={"center"}
              color={"white"}
              className="techworth-formula"

              // left={"70px"}
            >
              {/* <Typography display={{ xs: "block", md: "none", lg: "none" }}>
                hello
              </Typography> */}
              <Box
                xs={12}
                sm={6}
                md={6}
                lg={6}
                display="flex"
                flexDirection="row"
                alignItems="center"
                position={"absolute"}
                // zIndex={1}
                left={{ lg: -15, sm: -10, md: -15, xs: -15 }}
                bottom={{ lg: -10, sm: -18, md: -10, xs: -8 }}
                // right={10}
              >
                <Avatar
                  src="../../../assets/images/avatar/TRUE-SELFY-ICON-01.svg"
                  sx={{
                    width: "auto",
                    height: "110px",
                    filter: "drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.45))",
                  }}
                  className="techworth-logo-new"
                >
                  {" "}
                </Avatar>
              </Box>
              <Box sm={12} md={6} lg={6} display="flex" flexDirection="row" alignItems="center">
                <Typography
                  variant="p"
                  style={{
                    // fontFamily: "Montserrat, sans-serif",
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  className="techworth-label"
                >
                  TechWorth =
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  pt={{ xs: 1, md: 2, lg: 2 }}
                  pb={{ xs: 1, md: 2, lg: 2 }}
                  pl={{ xs: 1, md: 2, lg: 2 }}
                  pr={{ xs: 1, md: 2, lg: 2 }}
                >
                  <Typography
                    variant="p"
                    style={{
                      borderBottom: "2px solid",
                      textAlign: "center",
                    }}
                    className="techworth-label"
                  >
                    TechValue
                  </Typography>
                  <Typography variant="p" className="avatar-lebel techworth-label">
                    Market Salary
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* <Box
              display={{ xs: "block", lg: "block", md: "block" }}
              sx={{ pl: 1, opacity: 0.4 }}
            >
              <div>
                <img
                  src="../../../assets/images/SELF-ATTESTED-2.png"
                  width={"110px"}
                  alt="self-attested"
                  className="self-attested-logo"
                />
              </div>{" "}
            </Box> */}
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            display={"flex"}
            flexDirection={{
              xs: "column",
              md: "column",
              lg: "column",
              xl: "row",
            }}
            alignItems={"center"}
          >
            {/* techvalue and markesalary box */}
            <Grid
              sx={{
                ml: 2,
                mr: 2,
                mt: 3,
                p: 0.2,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                justifyContent: "center",
                borderRadius: 3,
                boxShadow: " 2px 2px 2px 2px rgba(255, 194, 24, 0.3)",
              }}
            >
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={{ xs: "center", md: "center", lg: "center" }}
                justifyContent={{
                  xs: "center",
                  md: "flex-start",
                  lg: "flex-start",
                }}
              >
                <Grid p={{ xs: 0, md: 2, lg: 2 }} mt={3}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                    justifyContent={{
                      xs: "center",
                      sm: "initial",
                      md: "initial",
                      lg: "initial",
                    }}
                  >
                    {!isMobile && (
                      <Box pr={1} display={"flex"} sx={{ width: "60", height: "60" }}>
                        <Avatar
                          className="label-img"
                          pr={1}
                          sx={{ width: 74, height: 74 }}
                          src="../../../assets/images/avatar/New-TechValue-icon.svg"
                        />
                      </Box>
                    )}

                    <Box
                      className="techvalue-label-new"
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}

                      // ml={2}
                    >
                      {!isMobile && (
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                              sx={{ fontWeight: "bold" }}
                              variant="h4"
                              component="h2"
                              // className="techvalue-label-new"
                            >
                              TechValue
                            </Typography>
                          </Box>
                          &nbsp; &nbsp;
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            pr={{ xs: 1, md: 1, lg: 2 }}
                          >
                            <Typography
                              sx={{ fontWeight: "bold" }}
                              variant="h4"
                              component="h2"
                              // className="value-label-new"
                            >
                              {/* {resultData?.results?.convertedTechValue} */}
                              {resultData?.results?.convertedTechValue
                                ? resultData?.results?.convertedTechValue
                                : RUPEE.format(Math.round(resultData?.results?.techValue || 0))}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {isMobile && (
                        <>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Box p={1} sx={{ width: "60", height: "60" }}>
                              <Avatar
                                className="label-img"
                                sx={{ width: 74, height: 74 }}
                                src="../../../assets/images/avatar/New-TechValue-icon.svg"
                              />
                            </Box>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              // alignItems={"center"}
                              sx={{ padding: "10px 10px 0px 10px" }}
                            >
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                  sx={{ fontWeight: "bold" }}
                                  variant="h4"
                                  component="h2"
                                  // className="techvalue-label-new"
                                >
                                  TechValue
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                // pr={{ xs: 1, md: 1, lg: 2 }}
                              >
                                <Typography
                                  sx={{ fontWeight: "bold" }}
                                  variant="h4"
                                  component="h2"
                                  // className="value-label-new"
                                >
                                  {resultData?.results?.convertedTechValue
                                    ? resultData?.results?.convertedTechValue
                                    : RUPEE.format(Math.round(resultData?.results?.techValue || 0))}
                                  {/* {resultData?.results?.convertedTechValue} */}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="caption"
                              p={1}
                              sx={{
                                padding: "0px 15px 15px 15px",
                              }}
                            >
                              (Value you bring to the organization)
                            </Typography>
                          </Box>
                        </>
                      )}
                      {/* <Box> */}

                      {!isMobile && (
                        <Typography variant="subtitle1" p={0}>
                          (Value you bring to the organization)
                        </Typography>
                      )}
                      {/* </Box> */}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      // justifyContent: "space-between",
                      pt: 2,
                      pb: 0,
                    }}
                  >
                    {!isMobile && (
                      <Box sx={{ width: "60", height: "60" }} pr={1}>
                        <Avatar
                          className="label-img"
                          sx={{ width: 74, height: 74 }}
                          src="../../../assets/images/avatar/New-Market-Salary-icon.svg"
                        />
                      </Box>
                    )}

                    <Box
                      className="techvalue-label-new"
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      // ml={2}
                    >
                      {!isMobile && (
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ fontWeight: "bold" }} variant="h4" component="h2">
                              Market Salary
                            </Typography>
                          </Box>
                          &nbsp; &nbsp;
                          <Box
                            sx={{ display: "flex", alignItems: "center" }}
                            // pl={{ xs: 2, md: 4, lg: 4, xl: 4 }}
                            // pt={{ xs: 1, md: 1, lg: 2 }}
                            // pr={{ xs: 1, md: 1, lg: 2 }}
                            // pb={{ xs: 1, md: 1, lg: 2 }}
                          >
                            <Typography sx={{ fontWeight: "bold" }} variant="h4" component="h2">
                              {/* {RUPEE.format(Math.round(resultData?.marketSalary || 0))} */}
                              {resultData?.results?.convertedMarketSalary
                                ? resultData?.results?.convertedMarketSalary
                                : RUPEE.format(Math.round(resultData?.results?.marketSalary || 0))}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {isMobile && (
                        <Box>
                          <Box
                            display={"flex"}
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            sx={{ padding: "10px 10px 0px 10px" }}
                          >
                            <Box sx={{ width: "60", height: "60" }} p={1}>
                              <Avatar
                                className="label-img"
                                sx={{ width: 74, height: 74 }}
                                src="../../../assets/images/avatar/New-Market-Salary-icon.svg"
                              />
                            </Box>
                            <Box
                              display={"flex"}
                              flexDirection={"column"}
                              // alignItems={"center"}
                              sx={{ padding: "10px 10px 0px 10px" }}
                            >
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold" }} variant="h4" component="h2">
                                  Market Salary
                                </Typography>
                              </Box>
                              {/* &nbsp;: &nbsp; */}
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ fontWeight: "bold" }} variant="h4" component="h2">
                                  {/* {RUPEE.format(Math.round(resultData?.marketSalary || 0))} */}
                                  {resultData?.results?.convertedMarketSalary
                                    ? resultData?.results?.convertedMarketSalary
                                    : RUPEE.format(
                                        Math.round(resultData?.results?.marketSalary || 0)
                                      )}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography
                              variant="caption"
                              textAlign={"center"}
                              sx={{
                                padding: "0px 15px 15px 15px",
                              }}
                            >
                              (Fair Salary you should expect to be paid)
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      <Box>
                        {!isMobile && (
                          <Typography variant="subtitle1" textAlign={"center"}>
                            (Fair Salary you should expect to be paid)
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                display={"flex"}
                // flexDirection={{ xs: "row", md: "row", lg: "row" }}
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent={"center"}
                alignItems={"center"}
                sx={{ py: 3 }}
                // pl={{ xs: 0, md: 8, lg: 15 }}
              >
                {/* <Box
                  style={{
                    height: "35px",
                    width: "35px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    color: "white",
                  }}
                >
                  <Typography>2</Typography>
                </Box> */}
                <Button
                  component="a"
                  variant="contained"
                  href="../../../assets/TrueSelfy-TechWorth-Factors.pdf"
                  target="_blank"
                >
                  TechWorth
                </Button>

                <Box
                  // lg={6}
                  // md={12}
                  // xs={12}
                  sx={
                    {
                      // position: "relative",
                      // display: "flex",
                      // alignItems: "center",
                      // pb: 1,
                    }
                  }
                  // zIndex={3}
                  // flexDirection={"column"}
                  // order={{ xs: 2, md: 2, lg: 2, xl: 2 }}
                >
                  {/* <Box lg={6} md={6} xs={12} sx={{ display: "flex", alignItems: "center" }}> */}
                  <ReactSpeedometer
                    className="speedometer"
                    segments={6}
                    customSegmentLabels={[
                      {
                        text: " 0-1",
                        position: "INSIDE",
                        color: "#fff",
                      },
                      {
                        text: "1-2",
                        position: "INSIDE",
                        color: "#fff",
                      },
                      {
                        text: "2-3",
                        position: "INSIDE",
                        color: "#fff",
                      },
                      {
                        text: "3-4",
                        position: "INSIDE",
                        color: "#fff",
                      },
                      {
                        text: "4-5",
                        position: "INSIDE",
                        color: "#fff",
                      },
                      {
                        text: "5+",
                        position: "INSIDE",
                        color: "#fff",
                      },
                    ]}
                    segmentColors={[
                      "rgba(215, 38, 38, 1)",
                      "rgba(255, 194, 24, 1)",
                      "rgba(43, 179, 68, 1)",
                      "rgba(13, 155, 33, 1)",
                      "rgba(255, 194, 24, 1)",
                      "rgba(215, 38, 38, 1)",
                    ]}
                    value={(
                      resultData?.results?.techValue / resultData?.results?.marketSalary || 0
                    ).toFixed(2)}
                    needleHeightRatio={0.6}
                    labelFontSize={"10px"}
                    valueTextFontSize={"23px"}
                    textColor={"#000"}
                    maxValue={6}
                    minValue={0}
                    needleTransition="easeElastic"
                    customSegmentStops={[0, 1, 2, 3, 4, 5, 6]}
                    height={160}
                    width={240}
                    ringWidth={40}
                    segmentValueFormatter={segmentValueFormatter}
                  />
                  {/* </Box> */}
                  {/* {!isMobile && (
                    <Box display={"flex"} flexDirection={"row"} pl={2}>
                      <Box
                        xs={12}
                        display={{
                          xs: "block",
                          sm: "none",
                          md: "none",
                          lg: "none",
                          xl: "none",
                        }}
                      >
                        {console.log(resultData?.evaluationTested, "resultData?.evaluationTested")}
                      </Box>

                      <Button
                        component="a"
                        variant="contained"
                        href="../../../assets/TrueSelfy-TechWorth-Factors.pdf"
                        target="_blank"
                      >
                        TechWorth
                      </Button>
                    </Box>
                  )} */}
                </Box>

                <Box
                  display={{
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  }}
                >
                  {resultData?.evaluationTested && resultData?.evaluationTested !== undefined ? (
                    <StyledBadge
                      badgeContent={resultData?.evaluationResultlength || 1}
                      color="primary"
                    >
                      <img
                        src={`../../../assets/images/peerreviewed.svg`}
                        width={"100px"}
                        alt="self-attested"
                        className="self-attested-logo"
                      />
                    </StyledBadge>
                  ) : (
                    <img
                      src={`../../../assets/images/SELF-ATTESTED.svg
                      `}
                      width={"100px"}
                      alt="self-attested"
                      className="self-attested-logo"
                    />
                  )}
                </Box>
                {/* <Button
                  component="a"
                  variant="contained"
                  href="../../../assets/TrueSelfy-TechWorth-Factors.pdf"
                  target="_blank"
                >
                  TechWorth
                </Button> */}
              </Grid>
              {isMobile && (
                <Grid display={"flex"} justifyContent={"center"} mb={2}>
                  <Box xs={12} position={"absolute"} top={963} zIndex={1}>
                    {/* <div className="box_ab"> */}
                    {/* <img
                      src={`${
                        resultData?.evaluationTested &&
                        resultData?.evaluationTested !== undefined
                          ? "../../../assets/images/peerreviewed.svg"
                          : "../../../assets/images/SELF-ATTESTED.svg"
                      }`}
                      Width={"80px"}
                      alt="sel-attested"
                    /> */}
                    {/* </div> */}
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    pl={{ xs: 3, sm: 0, lg: 0, xl: 0 }}
                  >
                    {/* <Link
                      href="../../../assets/TrueSelfy TechWorth Factors (1).pdf"
                      target="_blank"
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          backgroundColor: "rgba(241, 241, 241, 1)",
                          borderRadius: "100px",
                          padding: "10px 15px 10px 15px",
                        }}
                        variant="h4"
                        component="h2"
                        // className="techvalue-label-new"
                      >
                        TechWorth
                      </Typography>
                    </Link> */}
                  </Box>
                </Grid>
              )}
              <Grid item display={"flex"} flexDirection={"column"} alignItems={"center"}>
                <Box pb={2}>
                  {/* {User_Cover_letter ? (
                    <LoadingButton variant={"contained"} onClick={() => getBiolater()}>
                      Get your True Edge
                    </LoadingButton>
                  ) : (
                    ""
                  )} */}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    background: "rgba(241, 241, 241, 1)",
                    borderRadius: 100,
                    // width: "70%",
                    textAlign: "center",
                    justifyContent: "center",
                  }}
                  p={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}
                  flexDirection={{
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  }}
                >
                  <Typography>Evaluated for&nbsp;</Typography>

                  <Typography
                    noWrap
                    sx={{
                      // pr: 3,
                      fontWeight: "bold",
                      wordWrap: "break-word",
                      maxWidth: "400px",
                    }}
                  >
                    {resultData?.email}
                  </Typography>
                </Box>
              </Grid>
              {/* <Box display={"flex"} flexDirection={"row"} sx={{ width: "100%" }} p={2}> */}
              <Typography variant={"p"} textAlign="center" sx={{ my: 2 }}>
                This is an indicative figure based on your given answers.
              </Typography>

              <Dialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 700, p: { xs: 1, md: 1 } }}>
                  {/* {(resultData?.name).trim()}'s True Edge */}
                </DialogTitle>

                <DialogContent p={{ xs: 0.5, md: 1 }}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <CopyToClipboard text={text}>
                      <ContentCopyOutlinedIcon
                        onClick={handleClick}
                        sx={{ cursor: "pointer", pb: 1 }}
                      />
                    </CopyToClipboard>
                  </Box>
                  <DataComponent />
                </DialogContent>
              </Dialog>

              <Typography variant="p" textAlign="center" sx={{ mb: 2 }}>
                Ideally, your TechWorth must be at least 2 to 4 , if you are to be considered
                productive and useful in your organization.
              </Typography>
              {/* <Typography variant={"p"} color="black" className="answers">
                    Ideally, your TechWorth must be at least 2 to 4 , if you are to be considered
                    productive and useful in your organization.
                  </Typography> */}
              {/* </Box> */}
              {/* </Grid> */}
            </Grid>
            {/* Experiance box */}
            <Grid
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  maxHeight: "50%",
                  p: 4,
                  backgroundColor: "#fff",
                  borderRadius: 3,
                  boxShadow: " 2px 2px 2px 2px rgba(255, 194, 24, 0.3)",
                }}
              >
                <Typography
                  variant="h4"
                  color="rgba(43, 179, 68, 1) "
                  textAlign={"left"}
                  sx={{ fontWeight: "bold" }}
                >
                  {resultData?.userData?.experience} years experience with:
                </Typography>
                <ol style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  {resultData?.primarySkill?.map((item, index) => (
                    <li key={index}>{item?.name}</li>
                  ))}
                </ol>
                <Typography
                  variant="p"
                  color="rgba(43, 179, 68, 1) "
                  textAlign={"left"}
                  sx={{ fontWeight: "bold" }}
                  // alignItmes={"center"}
                  display={"flex"}
                >
                  All skills {<TooltipComponent message={allSkills} />}
                </Typography>

                {/* <DataComponent /> */}
              </Box>
            </Grid>
          </Grid>
          <Grid p={2} textAlign={"center"}>
            {/* <Typography variant={"p"} color="black" className="answers">
              This is an indicative figure based on your given answers.
            </Typography> */}
          </Grid>
        </Grid>
        <Grid
          item
          order={{ xs: 1, sm: 2, lg: 2, xl: 2 }}
          display={{ xs: "none", lg: "flex", md: "flex" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box alignItems={"center"} display={"flex"} justifyContent={"center"}>
            <img
              style={{ Width: "800px", width: "100%", height: "auto" }}
              src="../../../assets/images/pizza-box.svg"
              alt="pizza-box"
            />
          </Box>
        </Grid>
      </Grid>
      {/* End hero unit */}

      <Grid container wrap="nowrap" spacing={2}>
        <Grid
          item
          xs
          sx={{ display: "flex", justifyContent: "center" }}
          style={{ textAlign: "center", py: 5 }}
        >
          <Typography className="answers" pt={1}>
            This result for ID {resultData?._id} and time {resultData?.time}
            &nbsp;
            <br></br>has been calculated based on the parameters provided by the user
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 1 }}>
        <Button
          className="btn"
          variant="contained"
          endIcon={<HomeOutlinedIcon />}
          onClick={() => {
            navigate("/");
          }}
          // sx={{ display: "block", m: "0px auto", mt: 3, mb: 1 }}
        >
          Back to Home
        </Button>
      </Grid>
      {/* Footer */}
      <Footer />
      {/* End footer */}
    </React.Fragment>
  );
}

export default PreviousResult;
