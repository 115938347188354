// src/Banner.js
import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import Countdown from "./CountDown";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import PublicIcon from "@mui/icons-material/Public";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Lottie from "react-lottie";
import Divider from "@mui/material/Divider";
import NumberTicker from "./NumberTicker";
import ShinyTypography from "./ShinyTypography";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
// import usersAnimation from "../../../assets/images/users.json"; // Add your lottie animation files in the src/lotties folder

const Banner = ({ users, countries, cities, techWorthUsers }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    // animationData: usersAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const removeBanner = useFeatureFlag("remove_banner");



  const styles = {
    fontColor: "#FFC218",
    fontWeight: 400,
    labelColor: "#FFFFFF",
    firstLabelColor: "#FFFFFF",
  };

  return (
    <React.Fragment>
      <Container>
        { removeBanner ? ("") : (<Grid
          container
          spacing={2}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ pb: { lg: 3 } }}
        >
          <Grid item xs={12} lg={6.4}>
            <Box>
              <ShinyTypography>
                <Typography
                  sx={{
                    color: styles.firstLabelColor,
                    textAlign: { xs: "center", sm: "center", lg: "left" },
                    fontSize: { xs: "28px", sm: "32px", md: "42px" },
                    fontWeight: 600,
                  }}
                  // variant={"h1"}
                >
                  Try our app for free before it becomes paid!
                </Typography>
              </ShinyTypography>
            </Box>
          </Grid>
          <Grid item xs={0} md={0} lg={0.2}>
            <Box
              sx={{
                borderLeft: "2px solid #fff",
                height: "80px",
                display: { xs: "none", md: "none", lg: "block" },
              }}
            ></Box>
          </Grid>

          <Grid item xs={12} lg={4.4}>
            <Box sx={{ pb: { xs: 3, md: 0 } }}>
              <Countdown />
            </Box>
          </Grid>
        </Grid>)}
        <Grid container spacing={4} justifyContent={"center"}>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              textAlign="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ p: 1 }}>
                <img
                  style={{ maxWidth: "56px" }}
                  src="../../../assets/images/banner/icons/users.svg"
                  alt="techworthusers"
                  title={"TechWorth Users"}
                />
              </Box>
              <Box>
                {/* <Typography
                  variant="h2"
                  sx={{ fontWeight: styles.fontWeight, color: styles.fontColor }}
                >
                  {techWorthUsers}+
                </Typography> */}
                <NumberTicker targetNumber={techWorthUsers} />

                <Typography variant="p" sx={{ color: styles.labelColor }}>
                  TechWorth Users
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              textAlign="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ p: 1 }}>
                <img
                  style={{ maxWidth: "56px" }}
                  src="../../../assets/images/banner/icons/totalUsers.svg"
                  alt="totalusers"
                  title={"Total Users"}
                />
              </Box>
              <Box>
                {/* <Typography
                  variant="h2"
                  sx={{ fontWeight: styles.fontWeight, color: styles.fontColor }}
                >
                  {users}+
                </Typography> */}
                <NumberTicker targetNumber={users} />

                <Typography
                  variant="p"
                  sx={{
                    color: styles.labelColor,
                  }}
                >
                  Total Users
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              textAlign="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ p: 1 }}>
                <img
                  style={{ maxWidth: "56px" }}
                  src="../../../assets/images/banner/icons/cities.svg"
                  alt="cities"
                  title="Cities"
                />
              </Box>
              <Box>
                {/* <Typography
                  variant="h2"
                  sx={{ fontWeight: styles.fontWeight, color: styles.fontColor }}
                >
                  {results}+
                </Typography> */}
                <NumberTicker targetNumber={cities} />

                <Typography
                  variant="p"
                  sx={{
                    color: styles.labelColor,
                  }}
                >
                  Cities
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <Box
              textAlign="center"
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ p: 1 }}>
                <img
                  style={{ maxWidth: "56px" }}
                  src="../../../assets/images/banner/icons/countries.svg"
                  alt="countries"
                  title={"Countries"}
                />
              </Box>
              <Box>
                {/* <Typography
                  variant="h2"
                  sx={{ fontWeight: styles.fontWeight, color: styles.fontColor }}
                >
                  {countries}+
                </Typography> */}
                <NumberTicker targetNumber={countries} />
                <Typography
                  variant="p"
                  sx={{
                    color: styles.labelColor,
                  }}
                >
                  Countries
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* <Countdown /> */}
      </Container>
    </React.Fragment>
  );
};

export default Banner;
