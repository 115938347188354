/** @format */ import React, { useContext, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Controller, useForm } from "react-hook-form";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import "../styles.css";
import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import FooterComponent from "../components/Footer";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import TooltipComponent from "../components/common/Tooltip";
import "./Login.css";
import Checkbox from "@mui/material/Checkbox";
import { setupAuthInterceptor } from "../utils/apis";
import { OAuthCtx } from "../auth/AuthContext";
import { Alert, Button, Container, Divider, IconButton, InputAdornment } from "@mui/material";
import { userLogin, googleUserLogin } from "../components/stepper_form/stepper.helper";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";
import { setUserStatus } from "../redux/action/user";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DataDecryption from "../components/common/DataDecryption";
import { useMediaQuery } from "@mui/material";
import { getRedirectSignInURL, getRedirectSignOutURL } from "../components/common/GlobalFunction";
import { getEmail, saveToSessionStorage, saveTargetPath } from "../utils/storage";
import Loading from "../components/UI/Loading";
import { Helmet } from "react-helmet";
import { getPreviousResult } from "../components/stepper_form/stepper.helper";
// Import the configuration
import "../utils/amplifyConfig";
import ResponsiveDialog from "../components/common/ResponsiveDialog";
import { LinkedIn } from "@mui/icons-material";
import {
  MAINTENANCE_BREAK,
  SHOW_GOOGLE_LOGIN_BUTTON,
  SHOW_LINKEDIN_LOGIN_BUTTON,
} from "../components/common/GlobalVariable";

export default function Login() {
  const { setAuthState } = useContext(OAuthCtx);
  const { getAuthState } = useContext(OAuthCtx);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const staticRandomEncodeDecodeString = useFeatureFlag("staticRandomEncodeDecodeString");
  const secretEncodeDecodeString = useFeatureFlag("secretEncodeDecodeString");
  const isLoggedIn = useSelector((state) => state?.userReducer?.isLoggedIn || false);
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const uniqueReferredUserId = params.get("ref");
  const location = useLocation();
  const MaintenanceMessage = useFeatureFlag(MAINTENANCE_BREAK);
  const showLinkedInButton = useFeatureFlag(SHOW_LINKEDIN_LOGIN_BUTTON);
  const showGoogleButton = useFeatureFlag(SHOW_GOOGLE_LOGIN_BUTTON);

  const [openResponsiveDialog, setResponsiveDialog] = React.useState(false);
  const userType = params.get("link-type");

  useEffect(() => {
    const redirectLink = params.get("link");
    if (redirectLink === "/endorse-expertise") {
      saveTargetPath(redirectLink);
      saveToSessionStorage("uniqueReferredUserId", uniqueReferredUserId);
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn"),
          userRefId: sessionStorage.getItem("userRefId"),
          uniqueReferredUserId: uniqueReferredUserId,
        })
      );
    }
    if (isLoggedIn && redirectLink === "/endorse-expertise") {
      saveToSessionStorage("uniqueReferredUserId", uniqueReferredUserId);
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn"),
          userRefId: sessionStorage.getItem("userRefId"),
          uniqueReferredUserId: uniqueReferredUserId,
        })
      );

      navigate(redirectLink);
    } else if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm();

  const isMobile = useMediaQuery("(max-width:600px)");

  const AllowDomains = useFeatureFlag("AllowDomainsOnly");
  const EMAIL_GHOST_TEXT = `${isMobile ? "Enter Email" : "Enter your personal email id"}`;
  // const EMAIL_ERROR_MSG =
  //   "Oh no, that doesn't look like a valid email! Please double-check and try again.";
  // const OTP_GHOST_TEXT_OLD = `Enter the OTP received in email sent to ${getValues(
  //   "email"
  // )}`;
  const OTP_GHOST_TEXT = `Enter the OTP`;
  const OTP_ERROR_MSG = `Oh no, that doesn't look like a valid OTP! Please double-check and enter the OTP sent to ${getValues(
    "email"
  )}.`;

  const [errorHandler, setErrorHandler] = React.useState({
    error: 0,
    errorMsg: "",
  });
  const [cognitoUserstate, setCognitoUserstate] = React.useState();
  const [showOTPForm, setShowOTPForm] = React.useState(false);
  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });
  const [agreementCheck, setAgreementCheck] = React.useState(false);
  const Environment = process.env.REACT_APP_ENV;

  const [loadingButton, setLoadingButton] = React.useState(false);
  const [domainList, setDomainList] = React.useState([
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
  ]);

  function getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(intToHex).join("");
  }
  function intToHex(nr) {
    return nr.toString(16).padStart(2, "0");
  }
  //Login with register
  const handleSubmitCustom = async (data) => {
    if (MaintenanceMessage != "undefined") {
      setResponsiveDialog(true);
      return;
    }

    let email = data.email.toLowerCase();
    setLoadingButton(true);
    var idx1 = email.indexOf("@");
    if (AllowDomains) {
      if (idx1 > -1) {
        var splitStr = email.split("@");
        // var sub = splitStr[1].split(".");

        var splitStr1 = splitStr[1].split(".");
        var jointSplitStr = "." + splitStr1[1];

        if (domainList?.indexOf(splitStr[1]) < 0) {
          if (domainList?.indexOf(jointSplitStr) < 0) {
            setError("email", {
              type: "validomain",
              message: `Enter email with given domains`,
            });
            setLoadingButton(false);
            return;
          }
        }
      }
    }

    const params = {
      username: email,
      password: getRandomString(30),
      attributes: {
        email: email,
      },
    };
    var respons = [];

    try {
      //Check and user add user
      respons = await Auth.signUp(params);
    } catch (error) {
      if (error.message == "User already exists") {
        try {
          const cognitoUser = await Auth.signIn(email);
          setCognitoUserstate(cognitoUser);
          setShowOTPForm(true);
          const cstmError = { error: 0, errorMsg: "" };
          setLoadingButton(false);
          setErrorHandler(cstmError);
          localStorage.setItem("userEmail", cognitoUser.username);
        } catch (error) {
          const cstmError = { error: 1, errorMsg: error.message };
          setErrorHandler(cstmError);
          setLoadingButton(false);
        }
      } else {
        const cstmError = { error: 1, errorMsg: error.message };
        setErrorHandler(cstmError);
        setLoadingButton(false);
      }
    }
    if (respons.userConfirmed == true) {
      try {
        const cognitoUser = await Auth.signIn(email);
        setCognitoUserstate(cognitoUser);
        setShowOTPForm(true);

        const cstmError = { error: 0, errorMsg: "" };
        setErrorHandler(cstmError);
        localStorage.setItem("userEmail", cognitoUser.username);
        setLoadingButton(false);
      } catch (error) {
        const cstmError = { error: 1, errorMsg: error.message };
        setErrorHandler(cstmError);
        setLoadingButton(false);
      }
    }
  };

  const handleOTPSubmit = async (data) => {
    // event.preventDefault();
    //const data = new FormData(event.currentTarget);
    setLoadingButton(true);
    let otp = data.otp;
    let cognitoUser = {};
    try {
      cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUserstate, otp);
      if (cognitoUser.challengeName == "CUSTOM_CHALLENGE") {
        setError("otp", {
          type: "notvalid",
          message: "OTP is not valid please again!",
        });
      }
      try {
        var currentSession = null;
        currentSession = await Auth.currentSession();
        await setAuthState(currentSession);
        setupAuthInterceptor(getAuthState);
        const payload = {
          email: cognitoUser.username,
          registerTime: new Date(Date.now()).toUTCString(),
          lastLoginTime: new Date(Date.now()).toUTCString(),
          termsCondition: true,
          uniqueReferredUserId: params.get("ref"),
          type: userType,
        };
        await userLogin(payload, navigate, dispatch, setUserStatus);
      } catch (e) {
        setError("otp", {
          type: "notvalid",
          message: "OTP is not valid please again!",
        });
        setLoadingButton(false);
      }
    } catch (e) {
      setError("otp", {
        type: "NotAuthorizedException",
        message:
          "The OTP you have entered is invalid, and you have reached the maximum number of attempts allowed to enter it. Please return to the Sign In page and try again.",
      });
      setLoadingButton(false);
    }
  };

  function getAllowDomains() {
    try {
      axios
        .get(process.env.REACT_APP_API_BASE_URL + "/get-emailBlackListDomain", {
          options: {
            type: "json",
          },
        })
        .then((object) => {
          let EmailDomainList = DataDecryption(
            object.data,
            staticRandomEncodeDecodeString,
            secretEncodeDecodeString
          );
          setDomainList(EmailDomainList.emailDomainList[0].domains);
        });
    } catch (error) {
      console.log("error", error);
    }
  }
  const [tempToken, setTempToken] = React.useState(null);

  useEffect(() => {
    getAllowDomains();
    // if (process.env.REACT_APP_ENV === "dev") {
    //   let domainListDev = setDomainList([...domainList, "jspinfotech.com"]);
    //   setDomainList(domainListDev);
    // }
  }, []);

  const domains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
    "trueselfy.com",
  ];
  const showDomains = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "aol.com",
    "msn.com",
    "comcast.net",
    "yahoo.co.in",
    "live.com",
    "rediffmail.com",
    "ymail.com",
    "outlook.com",
    "googlemail.com",
    "rocketmail.com",
    "facebook.com",
    "yahoo.in",
    "me.com",
    "mail.com",
  ];

  return (
    <>
      {openResponsiveDialog && (
        <ResponsiveDialog
          openResponsiveDialog={openResponsiveDialog}
          setResponsiveDialog={setResponsiveDialog}
          MaintenanceMessage={MaintenanceMessage}
        />
      )}
      <>
        <Helmet>
          <title>Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise</title>
          <meta
            name="description"
            content="Boost my professional standing and assist in determining my rightful pay scale! Only 2 minutes of your time can make a significant impact. Your endorsement means a lot!"
          />
          <meta
            property="og:title"
            content="Future Pay Scale Prediction Calculator Tool Online | Endorse My Expertise"
          />
          <meta property="og:type" content="product" />
          <meta
            property="og:description"
            content="Boost my professional standing and assist in determining my rightful pay scale! Only 2 minutes of your time can make a significant impact. Your endorsement means a lot!"
          />
          <meta property="og:url" content="https://master.d3hqz1cwjn0bmv.amplifyapp.com/signin" />
        </Helmet>
        {Environment === "prod" ? (
          <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=AW-16506783500"></script>
            <script>
              {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){dataLayer.push(arguments);}
         gtag('js', new Date());
       
         gtag('config', 'AW-16506783500');              
          `}
            </script>
          </Helmet>
        ) : (
          ""
        )}
      </>
      {isLoggedIn ? (
        <Loading />
      ) : (
        <>
          <Grid
            container
            display={"flex"}
            flexDirection={"row"}
            justifyContent={{
              xs: "center",
              sm: "center",
              md: "center",
              lg: "left",
            }}
          >
            <Grid item>
              <Box p={3}>
                <a href="/">
                  <img
                    src={"../../assets/images/TrueSelfy-logo.svg"}
                    alt="true-selfy"
                    style={{
                      maxWidth: `${isMobile ? "80px" : ""}`,
                    }}
                  />
                </a>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            sx={{
              minHeight: { xs: "calc(100vh - 312px)", lg: "calc(100vh - 232px)" },
              height: "100%",
              backgroundImage: {
                xs: "none",
                md: showOTPForm
                  ? `url('/assets/images/otp-bg-pizza.png')`
                  : `url('/assets/images/signin-backimg.png')`,
              },
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: showOTPForm ? "top" : "center",
            }}
          >
            <Grid
              item
              display={"flex"}
              // flexDirection="row"
              justifyContent={"space-around"}
              alignItems="center"
              lg={7}
              md={7}
              xs={12}
            >
              {/* <Grid item lg={5} display={{ xs: "none", lg: "block", md: "none" }}>
              <img
                src={"../../assets/images/h_b_L_1.png"}
                alt="arrow1"
                style={{ zIndex: -1 }}
              />
            </Grid> */}
              {/* <Grid
              item
              lg={5}
              xs={12}
              alignItems="center"
              display="flex"
              flexDirection="column"
              sx={{ pl: 5, pr: 3 }}
              pl={{ xs: 3, lg: 0, md: 0 }}
              pr={{ xs: 3, lg: 0, md: 0 }}
            > */}
              {/* <Grid
              item
              pb={{ xs: 5, md: 5, lg: 5 }}
              pt={{ xs: 25, md: 20, lg: 10 }}
              display={{ xs: "block", lg: "block", md: "block" }}
            >
              <img
                src="../../assets/images/TrueSelfy-logo.svg"
                style={{ zIndex: 1, cursor: "pointer" }}
                onClick={() => navigate("/")}
                alt="logo"
              />
            </Grid> */}
              {/* <Grid item>
                <Grid item alignItems="center" className="sign-box"> */}
              {showOTPForm == false ? (
                <Box
                  sx={{
                    border: 1,
                    borderRadius: "25px",
                    borderColor: "#FFC218",
                    maxWidth: { xs: 281, sm: 373 },
                    width: "100%",
                    background: "#FFF",
                    p: { xs: "16px", md: "30px" },
                  }}
                  component="form"
                  onSubmit={handleSubmit(handleSubmitCustom)}
                >
                  <Typography
                    sx={{ fontSize: "32px", fontWeight: 500, mb: { xs: "5px", lg: "41px" } }}
                    textAlign={"center"}
                  >
                    Sign In
                  </Typography>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: "Email Address is required",
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                        message:
                          "Oh no, that doesn't look like a valid email! Please double-check and try again.",
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        error={errors?.email ? true : false}
                        {...field}
                        fullWidth
                        id="email"
                        type="text"
                        sx={{ mb: "23px" }}
                        // size="small"
                        InputProps={{
                          sx: {
                            paddingRight: "16px",
                          },
                          endAdornment: (
                            <InputAdornment position="start">
                              <TooltipComponent size="small" message={domainList} />
                            </InputAdornment>
                          ),
                        }}
                        label={EMAIL_GHOST_TEXT}
                        helperText={
                          <>
                            {errors?.email && errors?.email.message}
                            {errors?.email && errors.type === "validomain"?.email?.message}
                          </>
                        }
                      />
                    )}
                  />
                  <Box alignItems={"start"} display="flex">
                    <Checkbox
                      name="group"
                      checked={agreementCheck}
                      onChange={() => setAgreementCheck(!agreementCheck)}
                      size="small"
                      sx={{
                        p: "0px",
                        mr: "8px",
                      }}
                    />
                    <Typography
                      sx={{
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      I agree to the Terms of Service & Privacy policy
                    </Typography>
                  </Box>
                  <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{
                      mt: 3,
                      mb: 2,
                      fontSize: { xs: "15px", md: "20px" },
                    }}
                    disabled={!agreementCheck}
                    loading={loadingButton}
                  >
                    Get OTP
                  </LoadingButton>
                  {/* {process.env.REACT_APP_ENV != "prod" && ( */}
                  {/* <Button
                    variant="text"
                    fullWidth
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "#FFF",
                      color: "#232323",
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: 500,
                      border: "1px solid #26801E",
                      mb: "16px",
                      "&:hover,&:focus": {
                        border: "1px solid #26801E",
                      },
                    }}
                    onClick={async () => {
                      try {
                        // Perform Google federated sign-in
                        if (MaintenanceMessage != "undefined") {
                          setResponsiveDialog(true);
                          return;
                        }
                        await Auth.federatedSignIn({
                          provider: "Google",
                        });

                        console.log("User attributes updated successfully");
                      } catch (error) {
                        console.error(
                          "Error during Google federated sign-in or attribute update",
                          error
                        );
                      }
                    }}
                  >
                    <img
                      src="../../../assets/images/google-logo.png"
                      width="26px"
                      height="auto"
                      alt="google-logo"
                    />{" "}
                    Sign in with Google
                  </Button>
                  <Button
                    variant="text"
                    fullWidth
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "center",
                      bgcolor: "#FFF",
                      color: "#232323",
                      fontSize: { xs: "15px", md: "20px" },
                      fontWeight: 500,
                      border: "1px solid #007BB5",

                      "&:hover,&:focus": {
                        border: "1px solid #007BB5",
                        background: "rgba(0,123,181,0.04)",
                      },
                    }}
                  >
                    <img
                      src="../../../assets/images/linkedin-rounded.png"
                      width="26px"
                      height="auto"
                      alt="google-logo"
                    />{" "}
                    Sign in with Linkedin
                  </Button> */}

                  {/* <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    {showGoogleButton && (
                      <IconButton
                        sx={{
                          width: { xs: "40px", md: "50px" },
                          height: { xs: "40px", md: "50px" },
                          p: "0px",
                          "& >img": { width: "100%", height: "100%" },
                        }}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            if (MaintenanceMessage != "undefined") {
                              setResponsiveDialog(true);
                              return;
                            }
                            await Auth.federatedSignIn({
                              provider: "Google",
                            });

                            console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during Google federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img src="/assets/images/google-short-logo.png" alt="google-logo" />
                      </IconButton>
                    )}
                    {showLinkedInButton && (
                      <IconButton
                        sx={{
                          width: { xs: "40px", md: "50px" },
                          height: { xs: "40px", md: "50px" },
                          p: "0px",
                          "& >img": { width: "100%", height: "100%" },
                        }}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            if (MaintenanceMessage != "undefined") {
                              setResponsiveDialog(true);
                              return;
                            }
                            await Auth.federatedSignIn({
                              provider: "LinkedIn",
                            });

                            console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during Google federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img src="/assets/images/linkedin-short-logo.png" alt="linkedin-logo" />
                      </IconButton>
                    )}
                  </Box> */}

                  {/* )} */}
                  {(showGoogleButton || showLinkedInButton) && (
                    <Divider>
                      <span style={{ color: "#e0e0e0" }}>OR</span>
                    </Divider>
                  )}

                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 2,
                    }}
                  >
                    {showGoogleButton && (
                      <Button
                        variant="text"
                        fullWidth
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "#FFF",
                          color: "rgb(35, 35, 35)",
                          fontSize: { xs: "15px", md: "18px" },
                          fontWeight: 500,
                          p: { xs: "6px", md: "8px" },
                          border: "1.5px solid #878787",
                          // mb: "16px",
                          "&:hover,&:focus": {
                            border: "1px solid #26801E",
                          },
                        }}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            if (MaintenanceMessage != "undefined") {
                              setResponsiveDialog(true);
                              return;
                            }
                            await Auth.federatedSignIn({
                              provider: "Google",
                            });

                            console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during Google federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img
                          src="../../../assets/images/google-logo.png"
                          width="26px"
                          height="auto"
                          alt="google-logo"
                        />{" "}
                        Google
                      </Button>
                    )}
                    {showLinkedInButton && (
                      <Button
                        variant="text"
                        fullWidth
                        sx={{
                          display: "flex",
                          gap: 1,
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "#FFF",
                          color: "rgb(35, 35, 35)",
                          fontSize: { xs: "15px", md: "18px" },
                          fontWeight: 500,
                          p: { xs: "6px", md: "8px" },
                          border: "1.5px solid #878787",
                          "&:hover,&:focus": {
                            border: "1px solid #007BB5",
                            background: "rgba(0,123,181,0.04)",
                          },
                        }}
                        onClick={async () => {
                          try {
                            // Perform Google federated sign-in
                            if (MaintenanceMessage != "undefined") {
                              setResponsiveDialog(true);
                              return;
                            }
                            sessionStorage.setItem("clickedSocialMediaLogin", true);
                            await Auth.federatedSignIn({
                              provider: "LinkedIn",
                            });

                            console.log("User attributes updated successfully");
                          } catch (error) {
                            console.error(
                              "Error during Google federated sign-in or attribute update",
                              error
                            );
                          }
                        }}
                      >
                        <img
                          src="../../../assets/images/linkedin-square.png"
                          width="26px"
                          height="auto"
                          alt="google-logo"
                        />{" "}
                        Linkedin
                      </Button>
                    )}
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    border: 1,
                    borderRadius: "25px",
                    borderColor: "#FFC218",
                    maxWidth: { xs: 281, sm: 373 },
                    width: "100%",
                    background: "#FFF",
                    p: { xs: "16px", md: "30px" },
                  }}
                  component="form"
                  onSubmit={handleSubmit(handleOTPSubmit)}
                >
                  <Typography
                    sx={{ fontSize: "32px", fontWeight: 500, mb: "10px" }}
                    textAlign={"center"}
                  >
                    Enter the OTP
                  </Typography>
                  <Alert
                    icon={false}
                    severity="success"
                    sx={{ justifyContent: "center", mb: "37px" }}
                  >
                    {/* Received in the email sent to your email id */}
                    We’ve sent a verification code to your E-mail
                  </Alert>

                  <Controller
                    name="otp"
                    control={control}
                    rules={{
                      required: "OTP is required",
                      pattern: {
                        value: /^[0-9]+$/,
                        message: OTP_ERROR_MSG,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        error={errors?.otp ? true : false}
                        fullWidth
                        id="otp"
                        type="number"
                        label={OTP_GHOST_TEXT}
                        helperText={<>{errors?.otp && errors?.otp.message}</>}
                      />
                    )}
                  />
                  <Box alignItems={"center"} textAlign="center">
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 4, mb: 2 }}
                      loading={loadingButton}
                    >
                      Submit
                    </LoadingButton>
                    <Link href="/signin" color="primary" fontSize="small" textAlign={"center"}>
                      Back to Sign in
                    </Link>
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid
              item
              xs={0}
              md={5}
              sx={{
                display: { xs: "none", md: "flex" },
                ml: showOTPForm ? "-100px" : "0px",
                pb: showOTPForm ? "90px" : "0px",
              }}
              // flexDirection="row"
              alignItems="center"
              justifyContent={"center"}
            >
              {/* <Grid
              item
              xs={2}
              lg={2}
              display={{ xs: "none", lg: "block", md: "none" }}
            >
              <Box sx={{ pt: 15 }} className="signin-img">
                <img src="../../assets/images/h_b_L_3.png" alt="img" />
              </Box>
            </Grid> */}
              {!showOTPForm ? (
                <Box
                  sx={{
                    maxWidth: { lg: "656px", xl: "730px" },
                    width: "80%",
                    height: "100%",
                    display: "flex",
                    m: "0px auto",
                  }}
                >
                  <Img
                    alt="Pizza-Sign-in"
                    src="../../assets/images/Pizza-Sign-in.svg"
                    loading="lazy"
                  />
                </Box>
              ) : (
                <>
                  {/* <Box
              sx={{
                maxWidth: "305px",
                width: "100%",
                height: "auto",
                mr: "auto",
                mt: "12%",
                display: { xs: "none", lg: "inline-flex" },
              }}
            >
              <Img
                alt="Pizza-Sign-in"
                src="../../assets/images/otp-pizza-croped.png"
                loading="lazy"
                sx={{ width: "100%", height: "100%" }}
              />
            </Box> */}
                </>
              )}
            </Grid>
          </Grid>

          <FooterComponent />
        </>
      )}
    </>
  );
}
