import { FormHelperText, Grid, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import Typography from "@mui/material/Typography";
import Tooltip from "../../common/Tooltip";
function NumberQuestionComponent({ question }) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  question.rules.pattern = /^[0-9]+$/;
  return (
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography
        // variant="h6"
        // component="h1"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        // sx={{ fontWeight: "bold", lineHeight: "24px", py: 1 }}
        className="stepper-question"
      >
        {question.label}
        {question.rules.required && (
          <>
            &nbsp;<p style={{ color: "red" }}>*</p>
          </>
        )}

        {question.question_info != null &&
        question.question_info.replace(/\s/g, "")?.length != 0 ? (
          <>
            <Tooltip message={question?.question_info} />
          </>
        ) : (
          <></>
        )}
      </Typography>
      <Controller
        control={control}
        rules={question.rules}
        name={question.questionName}
        render={({ field }) => (
          <TextField
            {...field}
            autoFocus={question?.autoFocus}
            style={{ width: "100%" }}
            onChange={(e, newValue) => {
              setValue(question.questionName, e.target.value);
              question?.isSuggested &&
                setValue(`suggestedObject[${question.questionName}]`, e.target.value);
              // console.log(
              //   "getCerti",
              //   getValues(`suggestedObject[${question.questionName}]`)
              // );
              // console.log("e.target.value", e.target.value);
            }}
            type="number"
            // size="small"
            inputProps={{ inputMode: "numeric" }}
            variant="outlined"
          />
        )}
      />

      {errors[question.questionName]?.type === "required" && (
        // <p className={"errorMsg"}>{question.errorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.errorMsg}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "minLength" && (
        // <p className={"errorMsg"}>{question.minLengthErrorMsg}</p>
        <FormHelperText error className={"errorMsg"}>
          {question.minLengthErrorMsg}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "maxLength" && (
        // <p className={"errorMsg"}> {question.maxLengthErrorMsg} </p>
        <FormHelperText error className={"errorMsg"}>
          {" "}
          {question.maxLengthErrorMsg}{" "}
        </FormHelperText>
      )}
      {errors[question.questionName]?.type === "pattern" && (
        // <p className={"errorMsg"}> {"Enter only number"} </p>
        <FormHelperText error className={"errorMsg"}> {"Enter only number"} </FormHelperText>
      )}
      {/* <ErrorMessage message={"First name is required"} name={`${questionName}`} as={"text"} /> */}
    </Grid>
  );
}
export default NumberQuestionComponent;
