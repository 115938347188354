import React, { useEffect, useContext, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { OAuthCtx } from "../../auth/AuthContext";
import { setupAuthInterceptor } from "../../utils/apis";
import { saveTargetPath, getTargetPath } from "../../utils/storage";
import Loading from "../UI/Loading";
import { useDispatch, useSelector } from "react-redux";
import { customGoogleUserLogin, GetUserLoginType } from "../common/GlobalFunction";
import { setUserStatus } from "../../redux/action/user";
import { Auth } from "aws-amplify";
import { useFeatureFlag } from "@harnessio/ff-react-client-sdk";

// import LoadingReducer from "../../redux/reducer/loading";
// import Loadin

const ProtectedRoute = ({ children }) => {
  const { getAuthState } = useContext(OAuthCtx);
  const location = useLocation();
  const loading = useSelector((state) => state?.LoadingReducer?.state || false);
  setupAuthInterceptor(getAuthState);
  saveTargetPath(location.pathname);
  const userType = GetUserLoginType();
  const dispatch = useDispatch();
  const Admin_Access = useFeatureFlag("Admin_Access");
  const allowedEmails = JSON.parse(Admin_Access);
  const type = useSelector((state) => state.userReducer.type || "copy-link");


  try {
    const currentSession = async () => await Auth.currentSession();
    const hasLoggedIn = sessionStorage.getItem("hasLoggedIn") || false;
    const uniqueReferredUserId = sessionStorage.getItem("uniqueReferredUserId");

    if (currentSession !== null && (userType === "google" || userType === "linkedin")) {
      if (!hasLoggedIn) {
        // Call your API here to update login time
        customGoogleUserLogin(dispatch, uniqueReferredUserId, type);
        sessionStorage.setItem("hasLoggedIn", true);
      }
      // Handle response if needed
      // console.log("Login time updated successfully");
      // setHasLoggedIn(true); // Set the flag to indicate that the function has been called
    } else if (currentSession === null) {
      localStorage.clear();
      sessionStorage.clear();
      dispatch(
        setUserStatus({
          isLoggedIn: false,
          userRefId: sessionStorage.getItem("userRefId"),
          uniqueReferredUserId: uniqueReferredUserId,
        })
      );
    }
  } catch (error) {
    console.error("Error updating login time:", error);
  }

  if (loading) {
    return <Loading />;
  }

  return children;
};

export default ProtectedRoute;
