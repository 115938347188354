import React from "react";
import {
  Avatar,
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import BuildIcon from "@mui/icons-material/Build";
import Header from "../components/Header";
import { Build, Replay } from "@mui/icons-material";

const VersionSvg = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="103"
    height="159"
    viewBox="0 0 103 159"
    fill="none"
    class={className}
  >
    <path
      d="M51.4999 95.1004C75.3034 95.1004 94.5999 75.8039 94.5999 52.0004C94.5999 28.1969 75.3034 8.90039 51.4999 8.90039C27.6964 8.90039 8.3999 28.1969 8.3999 52.0004C8.3999 75.8039 27.6964 95.1004 51.4999 95.1004Z"
      fill="#DD7D1F"
    />
    <path
      d="M101.6 52.0005C101.6 79.6005 79.2 102.1 51.5 102.1V146.4"
      stroke="black"
      stroke-width="0.9434"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="2.26 2.26"
    />
    <path
      d="M31.8 98.0005C14 90.3005 1.5 72.6005 1.5 52.0005"
      stroke="black"
      stroke-width="0.9434"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-dasharray="2.26 2.26"
    />
    <path d="M47 146.3H56L51.5 158.5L47 146.3Z" fill="black" />
    <path
      d="M1.5 52.0004C1.5 24.4004 23.9 1.90039 51.6 1.90039C75.4 1.90039 95.4 18.6004 100.4 40.9004C101.2 44.5004 101.6 48.2004 101.6 52.0004"
      stroke="#DD7D1F"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

function ChangeLogV2() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12} px={{ xs: 0 }} my={{ xs: 0, sm: 3, md: 3, lg: 3 }}>
        <Typography
          sx={{
            fontSize: "44px",
            fontWeight: 600,
            color: (theme) => theme.palette.primary.main,
            textAlign: "center",
            mb: "69px",
          }}
        >
          {" "}
          Changelog
        </Typography>
        <Box
          component="div"
          sx={{
            height: { md: "942px", lg: "957px", xl: "985px" },
            display: "grid",
            overflow: "hidden",
            overflowX: "scroll",
            backgroundImage: {
              xs: `url('/assets/images/changelog-road-mobile.svg')`,
              md: `url('/assets/images/changelog-road.svg')`,
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: { xs: "top left", md: "center" },
            backgroundSize: { md: "contain", lg: "contain", xl: "contain" },
            px: { xs: 0, sm: 0, md: 3, xl: 18 },
            my: { xs: 0, sm: 3, md: 3, xl: 3 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              overflow: "hidden",
              overflowX: "scroll",
              "& .version-box": {
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "row-reverse", md: "column" },
                justifyContent: { xs: "start", md: "unset" },
                ml: { xs: "85px", sm: "85px", md: "unset" },
              },
              "& .version-box:nth-child(even)": {
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "row-reverse", md: "column-reverse" },
                ml: { xs: "39px", sm: "16px", md: "unset" },
              },

              "& .version-box:not(:nth-child(1))": {
                ml: { lg: "-154px", xl: "-124px" },
              },
              "& .version-box .version-svg": {
                transform: { xs: "rotate(90deg)", md: "none" },
              },
              "& .version-box:nth-child(even) .version-svg": {
                transform: { xs: "rotate(90deg)", md: "rotate(180deg)" },
              },

              "& .version-box .version-svg-box": {
                width: { xs: "76px", sm: "auto" },
                height: { xs: "52px", sm: "auto" },
                margin: {
                  xs: "auto 10px",
                  sm: "auto 70px",
                  md: "32px auto 18px",
                },
              },
              "& .version-box .version-svg-box .version-svg": {
                width: { xs: "100%", sm: "auto" },
                height: { xs: "100%", sm: "auto" },
              },
              "& .version-box:nth-child(odd) .version-svg-box p": {
                top: { xs: "50%", md: "52px" },
                left: { xs: "62%", sm: "73%", md: "50%" },
                fontSize: { xs: "10px", sm: "19px", lg: "19px" },
              },
              "& .version-box:nth-child(even) .version-svg-box p": {
                top: { xs: "50%", md: "106px" },
                left: { xs: "62%", sm: "73%", md: "50%" },
                fontSize: { xs: "10px", sm: "19px", lg: "19px" },
              },
              "& .version-box:nth-child(n) .version-svg path:nth-child(1)": {
                fill: "#DD7D1F",
              },
              ",& .version-box:nth-child(n) .version-svg path:nth-child(5)": {
                stroke: "#DD7D1F",
              },
              "& .version-box:nth-child(2n) .version-svg path:nth-child(1)": {
                fill: "#17B033",
              },
              ",& .version-box:nth-child(2n) .version-svg path:nth-child(5)": {
                stroke: "#17B033",
              },
              "& .version-box:nth-child(3n) .version-svg path:nth-child(1)": {
                fill: "#0BA2FF",
              },
              ",& .version-box:nth-child(3n) .version-svg path:nth-child(5)": {
                stroke: "#0BA2FF",
              },
              "& .version-box:nth-child(4n) .version-svg path:nth-child(1)": {
                fill: "#4520BA",
              },
              ",& .version-box:nth-child(4n) .version-svg path:nth-child(5)": {
                stroke: "#4520BA",
              },
              "& .version-box:nth-child(4n) .version-no-avatar": {
                bgcolor: "#4520BA",
              },
            }}
          >
            <Box
              className="version-box"
              sx={{
                display: "flex",
                flexDirection: "column",
                m: { xs: "25px", md: "0px" },
              }}
            >
              <Card
                sx={{
                  minWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  maxWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  width: "100%",
                  p: { xs: "10px", md: "22.29px 18.83px" },
                  border: "3px solid #DD7D1F",
                  borderRadius: "5px",
                  mt: { xs: "12.5px", md: "unset" },
                  mb: { xs: "12.5px", md: "18px" },
                }}
              >
                <List
                  disablePadding
                  sx={{
                    "& .MuiListItem-root": { alignItems: "start", p: "0px" },
                    "& .MuiListItemText-root": {
                      mt: "0px !important",
                      mb: { xs: "3px", md: "6px", lg: "8px" },
                    },
                    "& .MuiListItemText-root p": {
                      fontSize: { xs: "9px", sm: "15px", lg: "19px" },
                    },
                    "& .MuiListItemIcon-root": {
                      width: { xs: "15px", sm: "28.4px !important" },
                      height: { xs: "15px", sm: "28.4px " },
                    },
                    "& .MuiListItemIcon-root svg": {
                      fontSize: { xs: "11px", sm: "13px" },
                      color: "#FFF",
                    },
                  }}
                >
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#DD7D1F",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#DD7D1F",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Replay />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Few minor fx and compatibility issues fxed
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#DD7D1F",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
              <Box
                className="version-svg-box"
                sx={{ position: "relative", m: "0px auto", mb: "32px" }}
              >
                <VersionSvg className="version-svg" />
                <Typography
                  sx={{
                    position: "absolute",
                    transform: "translate(-50%,-50%)",
                    fontSize: "25px",
                    fontWeight: 700,
                    color: "#FFF",
                  }}
                >
                  {" "}
                  v1.0
                </Typography>
              </Box>
              <Avatar
                className="version-no-avatr"
                sx={{
                  width: { xs: 21, sm: 40 },
                  height: { xs: 21, sm: 40 },
                  border: "3px solid #FFF",
                  bgcolor: "#DD7D1F",
                  fontSize: { xs: 10, sm: 19 },
                  m: { xs: "auto 21px", md: "0px auto" },
                }}
              >
                1
              </Avatar>
            </Box>
            <Box
              className="version-box"
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <Card
                sx={{
                  minWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  maxWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  width: "100%",
                  p: { xs: "10px", md: "22.29px 18.83px" },
                  border: "3px solid #00B01C",
                  borderRadius: "5px",
                  mt: { xs: "12.5px", md: "unset" },
                  mb: { xs: "12.5px", md: "18px" },
                }}
              >
                <List
                  disablePadding
                  sx={{
                    "& .MuiListItem-root": { alignItems: "start", p: "0px" },
                    "& .MuiListItemText-root": {
                      mt: "0px !important",
                      mb: { xs: "3px", md: "6px", lg: "8px" },
                    },
                    "& .MuiListItemText-root p": {
                      fontSize: { xs: "9px", sm: "15px", lg: "19px" },
                    },
                    "& .MuiListItemIcon-root": {
                      width: { xs: "15px", sm: "28.4px !important" },
                      height: { xs: "15px", sm: "28.4px " },
                    },
                    "& .MuiListItemIcon-root svg": {
                      fontSize: { xs: "11px", sm: "13px" },
                      color: "#FFF",
                    },
                  }}
                >
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#17B033",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#17B033",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Replay />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Few minor fx and compatibility issues fxed
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#17B033",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
              <Box
                className="version-svg-box"
                sx={{ position: "relative", m: "0px auto", mb: "32px" }}
              >
                <VersionSvg className="version-svg" />
                <Typography
                  sx={{
                    position: "absolute",
                    transform: "translate(-50%,-50%)",
                    fontSize: "25px",
                    fontWeight: 700,
                    color: "#FFF",
                  }}
                >
                  {" "}
                  v1.0
                </Typography>
              </Box>
              <Avatar
                className="version-no-avatr"
                sx={{
                  width: { xs: 21, sm: 40 },
                  height: { xs: 21, sm: 40 },
                  border: "3px solid #FFF",
                  bgcolor: "#00B01C",
                  fontSize: { xs: 10, sm: 19 },
                  m: { xs: "auto 21px", md: "0px auto" },
                }}
              >
                2
              </Avatar>
            </Box>
            <Box
              className="version-box"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card
                sx={{
                  minWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  maxWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  width: "100%",
                  p: { xs: "10px", md: "22.29px 18.83px" },
                  border: "3px solid #0BA2FF",
                  borderRadius: "5px",
                  mt: { xs: "12.5px", md: "unset" },
                  mb: { xs: "12.5px", md: "18px" },
                }}
              >
                <List
                  disablePadding
                  sx={{
                    "& .MuiListItem-root": { alignItems: "start", p: "0px" },
                    "& .MuiListItemText-root": {
                      mt: "0px !important",
                      mb: { xs: "3px", md: "6px", lg: "8px" },
                    },
                    "& .MuiListItemText-root p": {
                      fontSize: { xs: "9px", sm: "15px", lg: "19px" },
                    },
                    "& .MuiListItemIcon-root": {
                      width: { xs: "15px", sm: "28.4px !important" },
                      height: { xs: "15px", sm: "28.4px " },
                    },
                    "& .MuiListItemIcon-root svg": {
                      fontSize: { xs: "11px", sm: "13px" },
                      color: "#FFF",
                    },
                  }}
                >
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#0BA2FF",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#0BA2FF",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Replay />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Few minor fx and compatibility issues fxed
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#0BA2FF",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
              <Box
                className="version-svg-box"
                sx={{ position: "relative", m: "0px auto", mb: "32px" }}
              >
                <VersionSvg className="version-svg" />
                <Typography
                  sx={{
                    position: "absolute",
                    transform: "translate(-50%,-50%)",
                    fontSize: "25px",
                    fontWeight: 700,
                    color: "#FFF",
                  }}
                >
                  {" "}
                  v3.0
                </Typography>
              </Box>
              <Avatar
                className="version-no-avatr"
                sx={{
                  width: { xs: 21, sm: 40 },
                  height: { xs: 21, sm: 40 },
                  border: "3px solid #FFF",
                  bgcolor: "#00A2FF",
                  fontSize: { xs: 10, sm: 19 },
                  m: { xs: "auto 21px", md: "0px auto" },
                }}
              >
                3
              </Avatar>
            </Box>
            <Box
              className="version-box"
              sx={{
                display: "flex",
                color: "red",
                flexDirection: "column-reverse",
              }}
            >
              <Card
                sx={{
                  minWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  maxWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  width: "100%",
                  p: { xs: "10px", md: "22.29px 18.83px" },
                  border: "3px solid #4520BA",
                  borderRadius: "5px",
                  mt: { xs: "12.5px", md: "unset" },
                  mb: { xs: "12.5px", md: "18px" },
                }}
              >
                <List
                  disablePadding
                  sx={{
                    "& .MuiListItem-root": { alignItems: "start", p: "0px" },
                    "& .MuiListItemText-root": {
                      mt: "0px !important",
                      mb: { xs: "3px", md: "6px", lg: "8px" },
                    },
                    "& .MuiListItemText-root p": {
                      fontSize: { xs: "9px", sm: "15px", lg: "19px" },
                    },
                    "& .MuiListItemIcon-root": {
                      width: { xs: "15px", sm: "28.4px !important" },
                      height: { xs: "15px", sm: "28.4px " },
                    },
                    "& .MuiListItemIcon-root svg": {
                      fontSize: { xs: "11px", sm: "13px" },
                      color: "#FFF",
                    },
                  }}
                >
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#4520BA",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#4520BA",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Replay />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Few minor fx and compatibility issues fxed
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#4520BA",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
              <Box
                className="version-svg-box"
                sx={{ position: "relative", m: "32px auto 18px" }}
              >
                <VersionSvg className="version-svg" />
                <Typography
                  sx={{
                    position: "absolute",
                    transform: "translate(-50%,-50%)",
                    fontSize: "25px",
                    fontWeight: 700,
                    color: "#FFF",
                  }}
                >
                  {" "}
                  v1.0
                </Typography>
              </Box>
              <Avatar
                className="version-no-avatr"
                sx={{
                  width: { xs: 21, sm: 40 },
                  height: { xs: 21, sm: 40 },
                  border: "3px solid #FFF",
                  bgcolor: "#4D1ABE",
                  fontSize: { xs: 10, sm: 19 },
                  m: { xs: "auto 21px", md: "0px auto" },
                }}
              >
                4
              </Avatar>
            </Box>
            <Box
              className="version-box"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Card
                sx={{
                  minWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  maxWidth: { xs: "167px", sm: "260px", lg: "376px" },
                  width: "100%",
                  p: { xs: "10px", md: "22.29px 18.83px" },
                  border: "3px solid #DD7D1F",
                  borderRadius: "5px",
                  mt: { xs: "12.5px", md: "unset" },
                  mb: { xs: "12.5px", md: "18px" },
                }}
              >
                <List
                  disablePadding
                  sx={{
                    "& .MuiListItem-root": { alignItems: "start", p: "0px" },
                    "& .MuiListItemText-root": {
                      mt: "0px !important",
                      mb: { xs: "3px", md: "6px", lg: "8px" },
                    },
                    "& .MuiListItemText-root p": {
                      fontSize: { xs: "9px", sm: "15px", lg: "19px" },
                    },
                    "& .MuiListItemIcon-root": {
                      width: { xs: "15px", sm: "28.4px !important" },
                      height: { xs: "15px", sm: "28.4px " },
                    },
                    "& .MuiListItemIcon-root svg": {
                      fontSize: { xs: "11px", sm: "13px" },
                      color: "#FFF",
                    },
                  }}
                >
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#DD7D1F",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#DD7D1F",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Replay />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Few minor fx and compatibility issues fxed
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    {" "}
                    <ListItemIcon
                      sx={{
                        minWidth: "unset",
                        display: "grid",
                        placeItems: "center",
                        bgcolor: "#DD7D1F",
                        borderRadius: "14px",
                        mr: "6px",
                      }}
                    >
                      {" "}
                      <Build />{" "}
                    </ListItemIcon>{" "}
                    <ListItemText>
                      <Typography
                        sx={{
                          fontSize: { xs: "9px", md: "15px", lg: "19px" },
                          fontWeight: 500,
                          lineHeight: "normal",
                        }}
                      >
                        Fixed: Notifcation bar starts
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
              </Card>
              <Box
                className="version-svg-box"
                sx={{ position: "relative", m: "0px auto", mb: "32px" }}
              >
                <VersionSvg className="version-svg" />
                <Typography
                  sx={{
                    position: "absolute",
                    transform: "translate(-50%,-50%)",
                    fontSize: "25px",
                    fontWeight: 700,
                    color: "#FFF",
                  }}
                >
                  {" "}
                  v1.0
                </Typography>
              </Box>
              <Avatar
                className="version-no-avatr"
                sx={{
                  width: { xs: 21, sm: 40 },
                  height: { xs: 21, sm: 40 },
                  border: "3px solid #FFF",
                  bgcolor: "#DD7D1F",
                  fontSize: { xs: 10, sm: 19 },
                  m: { xs: "auto 21px", md: "0px auto" },
                }}
              >
                5
              </Avatar>
            </Box>
          </Box>

          {/* <Box>
          <img src="/assets/images/changelog-road.svg" alt="changelog-road" />
        </Box> */}
        </Box>
      </Grid>
    </Grid>
  );
}

export default ChangeLogV2;
