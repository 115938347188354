import _, { reject } from "lodash";
import apis from "../../utils/apis";
// import Result from "../../pages/Result";
import axios from "axios";
import {
  setIsReferralUsers,
  setUserRefId,
  getCompanyName,
} from "../../utils/storage";
import { setUserOldForm } from "../../redux/action/setOldForm";
import { setUserPreviousResult } from "../../redux/action/setPreviousResult";
import { setUserResult } from "../../redux/action/result";
import { setUserStatus } from "../../redux/action/user";
import { setResultId } from "../../redux/action/result";
import {
  setReferralList,
  updateReferralList,
} from "../../redux/action/setReferralsList";
import toast from "react-hot-toast";
import ReactGA4 from "react-ga4";

export const companyConnection = (company) => {
  apis
    .GET({
      endpoint: `/set-company?company=${company}`,
      options: {
        type: "json",
      },
    })
    .then((object) => {
      console.log(object.data?.message);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addUserToGroup = (data) => {
  // apis
  //   .POST({
  //     endpoint: `/add-user-group`,
  //     payload: data,
  //     options: {
  //       type: "json",
  //     },
  //   })
  //   .then((object) => {
  //     console.log(object.data?.message);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
};

export const getCoverLeter = (
  userObject,
  setText,
  setLoading,
  dispatch,
  resultData
) => {
  setLoading(true);
  setText("Generating your cover letter....");

  apis
    .POST({
      endpoint: "/get-cover-leter",
      payload: userObject,
      dispatch,
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log("data helper", object.data);
      // setText(object?.data);
      setText(object.data.result);
      dispatch(
        setUserPreviousResult({
          ...resultData,
          coverLetter: object.data.result,
        })
      );
      // return object.data;
    });
};

export const getCoverLetterForResult = (
  userObject,
  setText,
  setLoading,
  dispatch,
  resultData
) => {
  setLoading(true);
  setText("Generating your cover letter....");
  apis
    .POST({
      endpoint: "/get-cover-leter",
      payload: userObject,
      dispatch,
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log("data helper", object.data);
      // setText(object?.data);
      setText(object.data.result);
      dispatch(
        setUserResult({ ...resultData, coverLetter: object.data.result })
      );
      // return object.data;
    });
};

export const getPreviousResult = async (dispatch) => {
  apis
    .GET({
      endpoint: "/get-referrals",
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log(object, "referrals");
      if (object?.status === 201) {
        dispatch(setReferralList(object?.data));
      } else {
      }
    });
  apis
    .GET({
      endpoint: "/get-lastResult",
      options: { type: "json" },
    })
    .then((object) => {
      dispatch(setUserPreviousResult(object.data.lastResult));
    });
};
export const getPreviousResultForHomepage = async (dispatch) => {
  apis
    .GET({
      endpoint: "/get-lastResult",
      options: { type: "json" },
    })
    .then((object) => {
      dispatch(setUserPreviousResult(object.data.lastResult));
    });
};
const path = sessionStorage.getItem("targetPath") || "/";
export const userLogin = async (
  payload,
  navigate,
  dispatch,
  setUserStatus,
  ReactGA4
) => {
  apis
    .POST({
      endpoint: "/create-user",
      payload: payload,
      options: { type: "json" },
    })
    .then((object) => {
      // window.location.replace("/");
      setIsReferralUsers(object?.data?.isReferralUsers);
      setUserRefId(object?.data?.userRefId);
      // console.log(object.data?.isReferralUsers, "isReferralUsers");
      // setIsReferralUsers(object?.data?.isReferralUsers);
      // console.log(object.data?.isReferralUsers, "isReferralUsers");
      getPreviousResult(dispatch);
      dispatch(
        setUserStatus({
          isLoggedIn:
            sessionStorage.getItem("isLoggedIn") === "true" ? true : false,
          userRefId: sessionStorage.getItem("userRefId"),

          // isReferralUsers: object?.data?.isReferralUsers,
        })
      );
      ReactGA4.event({
        category: "post-login",
        action: "visited",
        label: "post-login", // optional
      });

      const urlSearchString = window.location.search;
      const params = new URLSearchParams(urlSearchString);
      const redirectLink = params.get("link");
      if (redirectLink === "/endorse-expertise") {
        navigate("/endorse-expertise");
      } else {
        navigate(path);
      }
    });
};

export const googleUserLogin = async (payload, dispatch, setUserStatus) => {
  apis
    .POST({
      endpoint: "/create-user",
      payload: payload,
      options: { type: "json" },
    })
    .then((object) => {
      setIsReferralUsers(object?.data?.isReferralUsers);
      setUserRefId(object?.data?.userRefId);
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn"),
          userRefId: sessionStorage.getItem("userRefId"),
        })
      );
      ReactGA4.event({
        category: "User-Login-Event",
        action: "called",
        label: "post-login", // optional
      });
    });
};
export const isReferralUser = async (setUserStatus, dispatch) => {
  apis
    .GET({
      endpoint: "/get-isReferralUser",
      options: { type: "json" },
    })
    .then((object) => {
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn"),
          isReferralUsers: object?.data?.isReferralUsers,
        })
      );
    });
};

export const getResult = (
  userFormObject,
  setFinalResult,
  dispatch,
  setUserResult,
  setLoading,
  navigate,
  razorpay_payment_id,
  amount,
  invoiceDetails
) => {
  apis
    .POST({
      endpoint: "/get-result",
      payload: userFormObject,
      options: {
        type: "json",
      },
      dispatch,
      navigate,
      setUserResult,
    })
    .then((object) => {
      // console.log("object", object);
      // if (object?.status === 201) {
      //   window.sendinblue.track("done_result", {
      //     email: localStorage.userEmail,
      //   });
      // }
      setFinalResult(object?.data);
      object.data.razorpay_payment_id = razorpay_payment_id;
      object.data.amount = amount;

      dispatch(setUserResult(object?.data));
      dispatch(setUserOldForm(userFormObject));
      navigate(`/result/${object?.data?._id}`);
      dispatch(setLoading(false));
      if (object?.data?.amount > 0) {
        apis
          .POST({
            endpoint: "/payment",
            payload: {
              ResultId: object?.data?._id,
              amount: amount * 100,
              currency: "INR",
              razorpay_payment_id: razorpay_payment_id,
              date: new Date(Date.now()).toUTCString(),
              invoice: invoiceDetails,
            },
            options: {
              type: "json",
            },
          })
          .then(function (response) {
            // console.log(response);
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
      // return <Result />;
    });
};
export const saveUserFormData = (userFormData, dispatch) => {
  apis
    .POST({
      endpoint: "/save-userFormData",
      payload: userFormData,
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log("object", object);
      dispatch(setResultId(object.data.id));
      // setFinalResult(object.data);
      // return <Result />;
    });
};
export const getStepperQuestionForm = (
  methods,
  setStepperJsonObject,
  setQuestionJsonObject,
  setStepperLoader,
  navigate,
  toast,
  _isEmpty,
  flagValue,
  setUserFormData,
  dispatch,
  IncognitoModeStatus
) => {
  apis
    .GET({
      endpoint: "/get-stepperQuestionForm",
      options: {
        type: "json",
      },
    })
    .then(async (object) => {
      if (object.status === 201) {
        const { stepperObject, questionList, userFormData, userRefId } =
          object.data;
        setStepperJsonObject(stepperObject);
        setQuestionJsonObject(questionList);
        setUserRefId(userRefId);

        dispatch(
          setUserStatus({
            isLoggedIn: sessionStorage.getItem("isLoggedIn"),
            userRefId: sessionStorage.getItem("userRefId"),
            uniqueReferredUserId: sessionStorage.getItem(
              "uniqueReferredUserId"
            ),
          })
        );
        if (IncognitoModeStatus === false) {
          apis
            .GET({
              endpoint: "/get-lastResult",
              options: { type: "json" },
            })
            .then((object) => {
              dispatch(setUserPreviousResult(object.data.lastResult));
              // console.log(object.data.lastResult);
            });
        }
        if (!_isEmpty(userFormData)) {
          questionList.map((item) => {
            if (item.type === "select") {
              const questionValue = userFormData[item.questionName];
              const optionsSet = new Set(item.options);
              const isValueIncluded = optionsSet.has(questionValue);

              if (!isValueIncluded) {
                delete userFormData[item.questionName];
                // console.log("item.questionName", item.questionName);
              }
              // if (!item.options.includes(userFormData[item.questionName])) {

              //   // methods.setValue(item.questionName, "");
              // }
            } else if (item.type === "multiSelect") {
              if (!Array.isArray(userFormData[item.questionName])) {
                delete userFormData[item.questionName];
                return;
              }
              if (
                item.hasOwnProperty("dynamicUrlEndpoint") ||
                item.hasOwnProperty("certificateMultiselect") ||
                item.hasOwnProperty("skillMultiselect")
              ) {
                return;
              }

              // Filter out selected answers that are not in the provided options
              userFormData[item.questionName] = userFormData[
                item.questionName
              ].filter((selectedOption) => {
                // console.log("includes", item.options.includes(selectedOption));
                return item.options.includes(selectedOption);
              });
              // If no valid options are selected, remove the field
              if (userFormData[item.questionName].length === 0) {
                delete userFormData[item.questionName];
              }
            }
          });
          let temp = userFormData;
          temp.contributeFeedback = "";
          delete temp.referrals;
          setUserFormData(temp);
        }

        if (flagValue && IncognitoModeStatus === false) {
          delete userFormData.referrals;
          methods.reset(userFormData);
        } else {
          methods.reset("");
        }

        // console.log("object.data", object.data);
      } else {
        // alert("Sorry, something went wrong. Please go back.");
        navigate("/signin");
        sessionStorage.setItem("isLoggedIn", false);
        // localStorage.clear();
        // sessionStorage.clear();
      }

      setStepperLoader(false);
    });
};

let cancelToken = null;

export const getOptions = (setData, endpoint, setLoading) => {
  setData([]);
  setLoading(true);
  // Cancel the previous request if it exists
  if (cancelToken) {
    // console.log("cancelToken", cancelToken);
    cancelToken.cancel("Previous request cancelled");
  }

  // Create a new cancellation token for the current request
  try {
    cancelToken = axios.CancelToken.source();

    apis
      .GET({
        endpoint: endpoint,
        options: {
          type: "json",
          cancelToken: cancelToken.token,
        },
        cancelToken: cancelToken.token, // Pass the cancellation token to the GET function
      })
      .then((object) => {
        if (object?.status === 201) {
          var data = _.remove(object.data, function (n) {
            return n.name !== "Other";
          });

          setData(data);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was cancelled
          // console.log("Request cancelled:", error.message);
        } else {
          // Handle other errors
          // console.log("Error:", error.message);
        }
      });
  } catch (error) {
    // Handle any unexpected errors here
    console.error("Error:", error);
    setLoading(false);
  }
};
export const getReferrals = (setReferralsList, dispatch, setReferralList) => {
  apis
    .GET({
      endpoint: "/get-referrals",
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log(object, "referrals");
      if (object?.status === 201) {
        setReferralsList(object?.data);
        dispatch(setReferralList(object?.data));
      } else {
        setReferralsList([]);
      }
    });
};
export const getReferralsReferralPage = (setLoading, dispatch) => {
  apis
    .GET({
      endpoint: "/get-referrals",
      options: {
        type: "json",
      },
    })
    .then((object) => {
      setLoading(false);
      setUserRefId(object?.data?.userRefId);
      // console.log(object.data?.isReferralUsers, "isReferralUsers");
      // setIsReferralUsers(object?.data?.isReferralUsers);
      // console.log(object.data?.isReferralUsers, "isReferralUsers");
      dispatch(
        setUserStatus({
          isLoggedIn: sessionStorage.getItem("isLoggedIn"),
          userRefId: sessionStorage.getItem("userRefId"),
        })
      );
      // console.log(object, "referrals");
      if (object?.status === 201) {
        // setReferralsList(object?.data?.referrals);
        dispatch(setReferralList(object.data));
      } else {
        // setReferralsList([]);
      }
    });
};
export const deleteReferralUser = (
  referralsList,
  referralUserEmail,
  setSnackbarSeverity,
  setSnackbarMsg,
  setOpenSnackbar,
  dispatch
) => {
  try {
    const response = new Promise(async (resolve, reject) => {
      apis
        .POST({
          endpoint: "/delete-referral",
          payload: { email: referralUserEmail },
          options: {
            type: "json",
          },
        })
        .then((object) => {
          if (object.status === 201) {
            // setOpenSnackbar(true);

            const filteredList = referralsList?.map((item) => {
              if (item.email === referralUserEmail) {
                item.status = "deleted";
              }
              return item;
            });
            dispatch(updateReferralList(filteredList));
            resolve("referral deleted");

            apis
              .GET({
                endpoint: "/get-referrals",
                options: {
                  type: "json",
                },
              })
              .then((object) => {
                // console.log(object, "referrals");
                dispatch(setReferralList(object?.data));
              });
          } else {
            setSnackbarMsg("something went wrong");
            setSnackbarSeverity("error");
          }
        });
    });
    return (
      response.data,
      toast.promise(
        response,
        {
          loading: "Loading",
          success: (message) => `${message}`,
          error: (message) => `${message}`,
        },
        { position: "top-center" }
      )
    );
  } catch (e) {
    console.log("error", e);
  }
};
export const postUserCustomInput = (dynamicEndpoint, storeOtherValues) => {
  apis
    .POST({
      endpoint: dynamicEndpoint,
      payload: storeOtherValues,
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log("object", object.data);
      // setFinalResult(object.data);
      // return <Result />;
    });
};

export const submitSalaryFeedback = (
  resultId,
  feedbackSalary,
  salaryComment
) => {
  apis
    .POST({
      endpoint: "/save-feedback",
      payload: {
        resultId: resultId,
        feedbackSalary: feedbackSalary,
        salaryComment: salaryComment,
      },
      options: {
        type: "json",
      },
    })
    .then(() => {});
};
export const getResults = (setResults) => {
  apis
    .GET({
      endpoint: "/get-results",
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log("data helper", object.data);
      setResults(object?.data?.result);
      // return object.data;
    });
};
export const UserStatus = () => {
  apis
    .GET({
      endpoint: "/get-results",
      options: {
        type: "json",
      },
    })
    .then((object) => {
      // console.log("data helper", object.data);
      // setResults(object?.data?.result);
      // return object.data;
    });
};

//Evaluation functions

// export function getEvaluationQuestion(setEvaluationQuestion) {
//   apis
//     .GET({
//       endpoint: "/get-evaluation-form",
//       options: {
//         type: "json",
//       },
//     })
//     .then((response) => {
//       setEvaluationQuestion(response.data);
//     });
// }

export const callDummyApi = (endpoint) => {
  apis
    .GET({
      endpoint: endpoint,
      options: {
        type: "json",
      },
    })
    .then((object) => {});
};
var cancelSkillToken = null;
export const getSkillOptions = (
  setData,
  endpoint,
  setLoading,
  DataDecryption,
  getValues,
  questionName
) => {
  setData([]);
  setLoading(true);

  if (cancelSkillToken) {
    // console.log("cancelSkillToken", cancelSkillToken);
    cancelSkillToken.cancel("Previous request cancelled");
  }
  // console.log("");
  try {
    cancelSkillToken = axios.CancelToken.source();
    axios
      .get(process.env.REACT_APP_API_BASE_URL + endpoint, {
        cancelToken: cancelSkillToken.token,
        headers: {
          "Content-Type": "application/json",
          Company: getCompanyName(),
        },
      })
      .then((object) => {
        if (object?.status === 201) {
          var data = _.remove(DataDecryption(object.data), function (n) {
            return n.name !== "Other";
          });
          let filterData = [];
          if (questionName === "primarySkill") {
            const secondSelectedSkills = getValues("secondarySkill");
            const selectedSkillNames = new Set(
              secondSelectedSkills.map((skill) => skill.name.toLowerCase())
            );
            filterData = data.filter(
              (resp) => !selectedSkillNames.has(resp.name.toLowerCase())
            );
          } else if (questionName === "secondarySkill") {
            const primarySelectedSkills = getValues("primarySkill");
            const selectedSkillNames = new Set(
              primarySelectedSkills.map((skill) => skill.name.toLowerCase())
            );
            filterData = data.filter(
              (resp) => !selectedSkillNames.has(resp.name.toLowerCase())
            );
          }
          setData(filterData);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          // Handle other errors here
          console.error("Error:", error);
          setLoading(false);
        }
        // setLoading(false);
      });
  } catch (error) {
    // Handle any unexpected errors here
    console.error("Error:", error);
    setLoading(false);
  }
};
